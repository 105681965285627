import React, { useEffect, useState } from "react";
import {
    PaymentElement,
    LinkAuthenticationElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import {app_url} from "../../../services/APITemplate";
import style from "./SetupForm.module.css"

export default function SetupForm(props) {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "setup_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
            switch (setupIntent.status) {
                case "succeeded":
                    setMessage("Moyen de paiement enregistré");
                    break;
                case "processing":
                    setMessage("...");
                    break;
                case "requires_payment_method":
                    setMessage("Votre moyen de paiement n'a pas été validé, veuillez réessayer");
                    break;
                default:
                    setMessage("Erreur inconnue");
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: app_url + (props.isEvent ? "/event/register/" : props.isRegate ? "/regate/register/" : "/myevent/") + props.id.toString(),
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("Erreur inconnue");
        }

        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs"
    }

    return (
        <form id="payment-form" onSubmit={handleSubmit} className={style.form}>
            <PaymentElement id="payment-element" className={style.paymentElement} options={paymentElementOptions} />
            <button disabled={isLoading || !stripe || !elements} id="submit" className={style.button}>
            <span id="button-text">
              {isLoading ? <div className={style.spinner} id="spinner"></div> : "Enregister ma carte"}
            </span>
            </button>
            {/* Show any error or success messages */}
            {message && <div id="payment-message" className={style.paymentMessage}>{message}</div>}
        </form>
    );
}
