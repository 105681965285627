import React, {Component} from "react"
import globalStyles from "../../styles/globalStyles.module.css"
import styles from "./Resultat.module.css"

class Resultat extends Component {


    render () {

        return (
            <div>
                <h1>Resultats</h1>

                <div style={{display: "flex", flexDirection: "column", gap: "50px", marginTop: "70px"}}>
                    <div style={{display: "flex", gap: "50px", flexDirection: window.innerWidth > 800 ? "row" : "column"}}>
                        <div className={this.props.EP <= 17 ? styles.infosCardYellow : this.props.EP >= 30 ? styles.infosCardRed : styles.infosCardOrange}>
                            <div className={styles.category}>Épuisement professionnel</div>

                            <div className={styles.degree}>
                                {this.props.EP <= 17 ? "Degré faible" : this.props.EP >= 30 ? "Degré élevé" : "Degré modéré"}
                            </div>
                        </div>

                        <div style={{flex: 3, display: "flex", flexDirection: "column", gap: "5px", justifyContent: "center"}}>
                            <div className={this.props.EP <= 17 ? styles.titleYellow : this.props.EP >= 30 ? styles.titleRed : styles.titleOrange}>
                                {this.props.EP <= 17 ? "Degré faible" : this.props.EP >= 30 ? "Degré élevé" : "Degré modéré"}
                            </div>
                            <div className={this.props.EP <= 17 ? styles.subtitleYellow : this.props.EP >= 30 ? styles.subtitleRed : styles.subtitleOrange}>
                                Score : {this.props.EP}
                            </div>

                            <div style={{marginTop: "10px"}}>
                                L’épuisement professionnel (burn-out) est typiquement lié au rapport avec un travail vécu comme difficile, fatigant, stressant… Pour Maslach, il est différent d’une dépression car il disparaîtrait pendant les vacances.
                            </div>
                        </div>
                    </div>

                    <div style={{display: "flex", gap: "30px", flexDirection: window.innerWidth > 800 ? "row" : "column"}}>
                        <div className={this.props.D <= 5 ? styles.infosCardYellow : this.props.D >= 12 ? styles.infosCardRed : styles.infosCardOrange}>
                            <div className={styles.category}>Dépersonnalisation</div>

                            <div className={styles.degree}>
                                {this.props.D <= 5 ? "Degré faible" : this.props.D >= 12 ? "Degré élevé" : "Degré modéré"}
                            </div>
                        </div>

                        <div style={{flex: 3, display: "flex", flexDirection: "column", gap: "5px", justifyContent: "center"}}>
                            <div className={this.props.D <= 5 ? styles.titleYellow : this.props.D >= 12 ? styles.titleRed : styles.titleOrange}>
                                {this.props.D <= 5 ? "Degré faible" : this.props.D >= 12 ? "Degré élevé" : "Degré modéré"}
                            </div>
                            <div className={this.props.D <= 5 ? styles.subtitleYellow : this.props.D >= 12 ? styles.subtitleRed : styles.subtitleOrange}>
                                Score : {this.props.D}
                            </div>

                            <div style={{marginTop: "10px"}}>
                                La dépersonnalisation, ou perte d’empathie, se caractérise par une baisse de considération positive à l’égard des autres (clients, collègues…); c’est une attitude où la distance émotionnelle est importante, observable par des discours cyniques, dépréciatifs, voire même par de l’indifférence.                            </div>
                        </div>
                    </div>

                    <div style={{display: "flex", gap: "30px", flexDirection: window.innerWidth > 800 ? "row" : "column"}}>
                        <div className={this.props.AP <= 33 ? styles.infosCardYellow : this.props.AP >= 40 ? styles.infosCardRed : styles.infosCardOrange}>
                            <div className={styles.category}>Accomplissement personnel</div>

                            <div className={styles.degree}>
                                {this.props.AP <= 33 ? "Degré faible" : this.props.AP >= 40 ? "Degré élevé" : "Degré modéré"}
                            </div>
                        </div>

                        <div style={{flex: 3, display: "flex", flexDirection: "column", gap: "5px", justifyContent: "center"}}>
                            <div className={this.props.AP <= 33 ? styles.titleYellow : this.props.AP >= 40 ? styles.titleRed : styles.titleOrange}>
                                {this.props.AP <= 33 ? "Degré faible" : this.props.AP >= 40 ? "Degré élevé" : "Degré modéré"}
                            </div>
                            <div className={this.props.AP <= 33 ? styles.subtitleYellow : this.props.AP >= 40 ? styles.subtitleRed : styles.subtitleOrange}>
                                Score : {this.props.AP}
                            </div>

                            <div style={{marginTop: "10px"}}>
                                L’accomplissement personnel est un sentiment de
                                « soupape de sécurité» qui assurerait un équilibre en cas
                                d’épuisement professionnel et de dépersonnalisation.
                                Il est le garant d’un épanouissement au travail, d’un regard
                                positif sur les réalisations professionnelles.
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{marginTop: "20px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <a
                        className={globalStyles.button}
                        style={{width: "200px"}}
                        href={this.props.event ? "/event/" + this.props.event : "/parcours"}
                    >
                        Revenir {this.props.event ? "à l'événement" : "aux événements"}
                    </a>
                </div>


            </div>
        );
    }
}

export default Resultat;
