import React from "react"
import globalStyles from "../../styles/globalStyles.module.css"
import styles from "./Parcours.module.css"
import CardEvent from "./CardEvent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAnchor,
    faCalendarDay, faCheckCircle,
    faClock, faLock,
    faMapMarkerAlt, faMicrophone,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import parcours from "../../assets/logos/parcours.png"
import TemplateComponent, {api_url, mapStateToProps} from "../../services/APITemplate";
import {connect} from "react-redux";
import moment from "moment";
import NoParcours from "./NoParcours";

const SORTIE_MER = 0;
const SOIREE_THEME = 2;
const CONFERENCE = 1;

class Parcours extends TemplateComponent {

    constructor(props) {
        super(props);
        this.state = {
            events: [],
            job: null,
            antenne: null,
            antennes: [],
            type: null
        }
        this.load = {
            event: {
                start: false,
                end: false,
            }
        }
    }

    doesMustLog() {
        this.mustLog = 1
    }

    loadData() {
        this.loadEvents()
    }

    loadEvents() {
        if(!this.load.event.start) {
            this.load.event.start = true
            let request = new Request(api_url + "/parcours/events", {method: "GET", credentials: "include"});
            this.fetchResponseOrLoginOrError(request, true).then((response) => {
                return response.json()
            }).then((data) => {
                this.setState({events: data.events, job: data.job, antennes: data.antennes});
            })
        }
    }

    getTime(event) {
        let start = event.startDate.date;
        let end = event.endDate.date;
        if(moment(start).format("DD/MM/YYYY") === moment(end).format("DD/MM/YYYY")){
            return "De " + moment(start).format("HH:mm") + " à " + moment(end).format("HH:mm");
        } else {
            return "Du " + moment(start).format("DD/MM/YYYY HH:mm") + " au " + moment(end).format("DD/MM/YYYY HH:mm");
        }
    }

    getSelectAntennes() {
        let options = [];
        options.push(<option value="">Tous</option>);
        for(let i = 0; i < this.state.antennes.length; i++){
            options.push(<option value={this.state.antennes[i]}>{this.state.antennes[i]}</option>)
        }
        return options
    }

    getSelectTypes() {
        let options = [];
        options.push(<option value="">Tous</option>);
        options.push(<option value={SORTIE_MER}>Sortie en mer</option>)
        options.push(<option value={CONFERENCE}>Conférence</option>)
        options.push(<option value={SOIREE_THEME}>Soirée à thème</option>)
        return options
    }

    getEvents() {
        let content = [];
        let len = this.state.events.length;
        for(let i = 0; i < len; i++){
            if((this.state.antenne == null || this.state.antenne == "" || this.state.antenne == this.state.events[i].place.antenne) && (this.state.type == null || this.state.type == "" || this.state.type == this.state.events[i].type) ){
                content.push(
                    <CardEvent
                        date={moment(this.state.events[i].startDate.date).format( "DD/MM/YYYY")}
                        titre={this.state.events[i].name}
                        type={this.state.events[i].type}
                        place={this.state.events[i].place.name + " - " + this.state.events[i].place.antenne}
                        time={this.getTime(this.state.events[i])}
                        capacity={this.state.events[i].capacity}
                        available={this.state.events[i].capacity - this.state.events[i].participants}
                        price={this.state.events[i].payType === 2 ? "Prix : " + this.state.events[i].payAmount.toFixed(2) + "€" : "Gratuit" }
                        caution={this.state.events[i].payType === 1 ? this.state.events[i].payAmount.toFixed(2) + "€" : null}
                        register={this.state.events[i].register}
                        link={"/event/" + this.state.events[i].id}
                        state={this.state.events[i].state}
                        job={this.state.job}
                    />
                )
            }
        }
        return content
    }

    render() { // follow this template
        if(!this.canRender()) {
            return this.noRender()
        }
        return (
            <div style={{minHeight: "calc(100vh - 80px)", position: "relative"}}>
                {this.head()}
                {this.flashtoDisplay}

                <div>
                    <div className={globalStyles.mainContainer}>
                        <h1>Le Parcours GEM</h1>
                        <div style={{margin: "20px 0 30px"}}>Les sorties en mer sont réservées aux soignants ayant un besoin urgent de prendre l'air (état de fragilité psychologique et/ou émotionnelle).
                            <br/>
                            En reservant, vous vous engagez à venir. En cas d'annulation merci de prévenir minimum 48h à l'avance <a href="mailto:contact@guerirenmer.com">en nous contactant</a>. Dans le cas contraire, la caution déposée au moment de l'inscription sera retenue. Merci pour votre participation et compréhension.
                        </div>


                        {window.innerWidth > 800 && this.state.events.length > 0 &&
                            <div className={styles.card}>

                                <div className={styles.titleContainer}>
                                    Prochain événement
                                </div>

                                <div style={{display: "flex"}}>
                                    <img src={parcours} className={styles.img} alt={"parcours"}/>

                                    <div className={styles.contentContainer}>
                                        <div className={styles.title}>
                                            {this.state.events[0].name}
                                        </div>

                                        <div style={{display: "flex", justifyContent: "space-between"}}>
                                            <div className={styles.infosContainer}>
                                                <div className={styles.lineContainer}>
                                                    <div className={styles.icon}>
                                                        <FontAwesomeIcon icon={faCalendarDay}/>
                                                    </div>
                                                    <div className={styles.text}>
                                                        <b>{moment(this.state.events[0].startDate.date).format("DD/MM/YYYY")}</b>
                                                    </div>
                                                </div>

                                                <div className={styles.lineContainer}>
                                                    <div className={styles.icon}>
                                                        <FontAwesomeIcon
                                                            icon={
                                                                this.state.events[0].type === SORTIE_MER ? faAnchor :
                                                                    this.state.events[0].type === SOIREE_THEME ? faUsers :
                                                                        this.state.events[0].type === CONFERENCE ? faMicrophone :
                                                                            null
                                                            }
                                                        />
                                                    </div>
                                                    <div className={styles.text}>
                                                        {this.state.events[0].type === SORTIE_MER ? "Sortie en mer" :
                                                            this.state.events[0].type === SOIREE_THEME ? "Soirée à thème" :
                                                                this.state.events[0].type === CONFERENCE ? "Conférence" :
                                                                    null
                                                        }
                                                    </div>
                                                </div>
                                                <div className={styles.lineContainer}>
                                                    <div className={styles.icon}>
                                                        <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                                    </div>
                                                    <div className={styles.text}>
                                                        {this.state.events[0].place.name + " - " + this.state.events[0].place.antenne}
                                                    </div>
                                                </div>
                                                <div className={styles.lineContainer}>
                                                    <div className={styles.icon}>
                                                        <FontAwesomeIcon icon={faClock}/>
                                                    </div>
                                                    <div className={styles.text}>
                                                        {this.getTime(this.state.events[0])}
                                                    </div>
                                                </div>
                                                <div className={styles.lineContainer}>
                                                    <div className={styles.icon}>
                                                        <FontAwesomeIcon icon={faUsers}/>
                                                    </div>
                                                    <div className={styles.text}>
                                                        {this.state.events[0].capacity === 0 ? "Pas de limite de place" : this.state.events[0].capacity + " places"}
                                                    </div>
                                                </div>
                                                {!(this.state.events[0].capacity === 0) &&
                                                    <div className={styles.remainingPlace}
                                                         style={{color: this.state.events[0].capacity - this.state.events[0].participants > 10 ? "var(--green)" : this.state.events[0].capacity - this.state.events[0].participants > 0 ? "var(--orange)" : "var(--red)"}}>
                                                        {(this.state.events[0].capacity - this.state.events[0].participants) > 0 ? "(" + (this.state.events[0].capacity - this.state.events[0].participants) + " places disponibles)" : "Plus de places disponibles"}
                                                    </div>
                                                }
                                            </div>

                                            <div className={styles.price}>
                                                {this.state.events[0].payType === 2 ? "Prix : " + this.state.events[0].payAmount.toFixed(2) + "€" : "Gratuit"}
                                                {this.state.events[0].payType === 1 &&
                                                    <div style={{fontSize: "12px"}}>
                                                        Une caution de {this.state.events[0].payAmount.toFixed(2)} €
                                                        vous sera demandée.
                                                    </div>
                                                }
                                            </div>
                                        </div>


                                        <div className={styles.buttonContainer}>
                                            {!this.state.job ?
                                                <div style={{margin: "20px auto", textAlign: "center", color: "var(--red)", fontWeight: "bold"}}>
                                                    <FontAwesomeIcon icon={faLock} color={"var(--red)"} style={{marginRight: "0.5rem"}}/>
                                                    Cet événement est reservé aux soignants
                                                </div>
                                                :
                                                this.state.events[0].register ?
                                                <div style={{
                                                    margin: "auto",
                                                    color: "var(--green)",
                                                    fontWeight: "bold"
                                                }}>Vous êtes déjà inscrit à cet événement.</div>
                                                :
                                                this.state.events[0].state === 2 ?
                                                    <div style={{margin: "auto", color: "var(--blue)", fontWeight: "bold"}}>Les inscriptions à cet événement sont fermées.</div>
                                                    :
                                                    <a href={"/event/" + this.state.events[0].id}
                                                       className={globalStyles.button}
                                                       style={{width: "50%", margin: "auto"}}>
                                                        {"Voir plus"}
                                                    </a>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        }

                        <hr/>

                        <h2>Toutes les sorties</h2>

                        <div style={{display: "flex", gap: "10px", width: "min(500px, 100%)"}}>
                            <div className={globalStyles.inputContainer} style={{flex: 1}}>
                                <label className={globalStyles.label}>Lieu</label>
                                <div className={globalStyles.inputArea}>
                                    <select
                                        className={globalStyles.inputInArea}
                                        value={this.state.antenne}
                                        onChange={(event) => this.setState({antenne: event.target.value})}
                                    >
                                        {this.getSelectAntennes()}
                                    </select>
                                </div>
                            </div>

                            <div className={globalStyles.inputContainer} style={{flex: 1}}>
                                <label className={globalStyles.label}>Type</label>
                                <div className={globalStyles.inputArea}>
                                    <select
                                        className={globalStyles.inputInArea}
                                        value={this.state.type}
                                        onChange={(event) => this.setState({type: event.target.value})}
                                    >
                                        {this.getSelectTypes()}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className={styles.container}>
                            {this.getEvents()}
                        </div>

                    </div>
                </div>


                {this.foot()}
            </div>
        )
    }
}

export default connect(mapStateToProps)(Parcours);
