import React from "react"
import globalStyles from "../../styles/globalStyles.module.css"
import styles from "./Regate.module.css"
import TemplateComponent from "../../services/APITemplate";


class NoPlaces extends TemplateComponent {

    constructor(props) {
        super(props);
        this.state = {
            skipperData: this.props.skipperData,
            packCompo: this.getBinary(this.props.regateEventInfos.packCompo),
            canGoNext: false,
        }
    }

    getBinary(n){
        let binary = n.toString(2).split('').reverse()
        while(binary.length < 4) {
            binary.push("0")
        }
        return binary
    }

    getInfos() {
        return this.state.skipperData;
    }

    getPrice(price){
        if(price === 0) return "Gratuit";
        return price.toFixed(2) + " €";
    }

    updateParticipateIn(i) {
        let partIn = this.state.skipperData.skipper.participateIn.toString(2).split('').reverse()
        while(partIn.length < 4) {
            partIn.push("0")
        }
        partIn[i] = partIn[i] === "0" ? "1" : "0";

        this.setState({skipperData: {...this.state.skipperData, skipper: {...this.state.skipperData.skipper, participateIn: parseInt(partIn.reverse().join(''), 2)}}})

    }

    getPack() {
        let partIn = [...this.state.skipperData.skipper.participateIn.toString(2).split('')].reverse()
        while(partIn.length < 4) {
            partIn.push("0")
        }
        for(let i = 0; i < this.state.packCompo.length; i++) {
            if(this.state.packCompo[i] === "1") {
                partIn[i] = "1";
            }
        }
        let participateIn = parseInt([...partIn].reverse().join(''), 2)
        this.setState({partIn: [...partIn].reverse()})
        this.state.skipperData.skipper.participateIn = participateIn;
        this.forceUpdate();

    }

    canGoNext() {
        let can = this.state.skipperData.boat.name !== "" &&
            this.state.skipperData.boat.length !== "" &&
            this.state.skipperData.boat.model !== "" &&
            this.state.skipperData.boat.nbPlaces > 0 &&
            this.state.skipperData.boat.insurance !== null &&
            this.state.skipperData.skipper.lastName !== "" &&
            this.state.skipperData.skipper.firstName !== "" &&
            this.state.skipperData.skipper.email !== "" &&
            this.state.skipperData.skipper.phone !== "" &&
            this.state.skipperData.skipper.participateIn !== 0;
        if(can !== this.props.currentCanNext) {
            this.props.canNext(can)
        }
    }


    render () {
        this.canGoNext()

        let partIn = [...this.state.skipperData.skipper.participateIn.toString(2).split('').reverse()]
        while(partIn.length < 4) {
            partIn.push("0")
        }

        return (
            <div style={{width: "100%"}}>

                <div className={styles.content} style={{overflow: 'auto', paddingBottom: 100}}>
                    <h1>Inscription</h1>

                    <h2>Information du bateau</h2>

                    <div className={styles.inputRow} style={{position: "relative"}}>
                        <div className={styles.choicePart} style={{padding: 0}}>
                            <div className={`${globalStyles.radioContainer} ${styles.clickable}`}
                                 style={{alignItems: "flex-start"}}
                            >
                                <div style={{display: "flex", gap: "5px", flexDirection: "column"}}>
                                    <div style={{display: "flex", gap: "10px"}}>
                                        <span>Inscription du bateau</span>
                                        <span className={styles.price}>
                                            {this.getPrice(this.props.regateEventInfos.boatPrice)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.inputRow}>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Nom du bateau*</label>
                            <input type={"text"} name={"name"} className={globalStyles.input}
                                   value={this.state.skipperData.boat.name}
                                   onChange={(event) => {
                                       this.setState({skipperData: {...this.state.skipperData, boat: {...this.state.skipperData.boat, name: event.target.value}}})
                                   }}
                            />
                        </div>
                    </div>

                    <div className={styles.inputRow}>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Marque/modèle*</label>
                            <input type={"text"} name={"model"} className={globalStyles.input}
                                   value={this.state.skipperData.boat.model}
                                   onChange={(event) => {
                                       this.setState({skipperData: {...this.state.skipperData, boat: {...this.state.skipperData.boat, model: event.target.value}}})
                                   }}
                            />
                        </div>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Longueur (en m)*</label>
                            <input type={"text"} name={"length"} className={globalStyles.input}
                                   value={this.state.skipperData.boat.length}
                                   onChange={(event) => {
                                       this.setState({skipperData: {...this.state.skipperData, boat: {...this.state.skipperData.boat, length: event.target.value}}})
                                   }}
                            />
                        </div>
                    </div>

                    <div className={styles.inputRow}>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Assurance* (fichier pdf)</label>
                            <input type={"file"} name={"insurance"} className={globalStyles.input}
                                   onChange={(event) => {
                                       this.setState({skipperData: {...this.state.skipperData, boat: {...this.state.skipperData.boat, insurance: event.target.files[0]}}})
                                       let reader = new FileReader()
                                       reader.onloadend = function(ev) {
                                           window.localStorage.setItem("insurance", this.result);
                                       }
                                       reader.readAsDataURL(event.target.files[0]);
                                   }}
                            />
                        </div>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Certificat de jauge (fichier pdf)</label>
                            <input type={"file"} name={"jauge"} className={globalStyles.input}
                                   onChange={(event) => {
                                       this.setState({skipperData: {...this.state.skipperData, boat: {...this.state.skipperData.boat, jauge: event.target.files[0]}}})
                                       let reader = new FileReader()
                                       reader.onloadend = function(ev) {
                                           window.localStorage.setItem("jauge", this.result);
                                       }
                                       reader.readAsDataURL(event.target.files[0]);
                                   }}
                            />
                        </div>
                    </div>

                    <div className={styles.inputRow}>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Nombre de places total*</label>
                            <input type={"text"} name={"nbPlaces"} className={globalStyles.input}
                                   value={this.state.skipperData.boat.nbPlaces}
                                   onChange={(event) => {
                                       this.setState({skipperData: {...this.state.skipperData, boat: {...this.state.skipperData.boat, nbPlaces: event.target.value}}})
                                   }}
                            />
                        </div>
                        <div style={{flex: 1}}/>
                    </div>

                    <h2>Information du skipper</h2>


                    <div className={styles.inputRow}>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Nom*</label>
                            <input type={"text"} name={"lastName"} className={globalStyles.input}
                                   value={this.state.skipperData.skipper.lastName}
                                   onChange={(event) => {
                                       this.setState({skipperData: {...this.state.skipperData, skipper: {...this.state.skipperData.skipper, lastName: event.target.value}}})
                                   }}
                            />
                        </div>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Prénom*</label>
                            <input type={"text"} name={"firstName"} className={globalStyles.input}
                                   value={this.state.skipperData.skipper.firstName}
                                   onChange={(event) => {
                                       this.setState({skipperData: {...this.state.skipperData, skipper: {...this.state.skipperData.skipper, firstName: event.target.value}}})
                                   }}
                            />
                        </div>
                    </div>

                    <div className={styles.inputRow}>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Adresse e-mail*</label>
                            <input type={"text"} name={"email"} className={globalStyles.input}
                                   value={this.state.skipperData.skipper.email}
                                   onChange={(event) => {
                                       this.setState({skipperData: {...this.state.skipperData, skipper: {...this.state.skipperData.skipper, email: event.target.value}}})
                                   }}
                                   disabled
                            />
                        </div>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Numéro de téléphone*</label>
                            <input type={"text"} name={"firstName"} className={globalStyles.input}
                                   value={this.state.skipperData.skipper.phone}
                                   onChange={(event) => {
                                       this.setState({skipperData: {...this.state.skipperData, skipper: {...this.state.skipperData.skipper, phone: event.target.value}}})
                                   }}
                                   disabled
                            />
                        </div>
                    </div>

                    <div className={styles.inputRow}>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Numéro de licence FFV</label>
                            <input type={"text"} name={"ffv"} className={globalStyles.input}
                                   value={this.state.skipperData.skipper.ffv}
                                   onChange={(event) => {
                                       this.setState({skipperData: {...this.state.skipperData, skipper: {...this.state.skipperData.skipper, ffv: event.target.value}}})
                                   }}
                            />
                        </div>

                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Niveau</label>
                            <div className={globalStyles.inputArea}>
                                <select
                                    className={globalStyles.inputInArea}
                                    value={this.state.skipperData.skipper.sailingLevel}
                                    onChange={(event) => {
                                        this.setState({skipperData: {...this.state.skipperData, skipper: {...this.state.skipperData.skipper, sailingLevel: event.target.value}}})

                                    }}
                                >
                                    <option value={0}>Je n'ai jamais fait de voile</option>
                                    <option value={1}>J'ai des bases en voile</option>
                                    <option value={2}>J'ai des compétences en voile</option>
                                    <option value={3}>J'ai déjà regaté</option>
                                    <option value={4}>Je suis regatier confirmé</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className={styles.inputRow}>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Sexe</label>
                            <div className={globalStyles.inputArea}>
                                <select
                                    className={globalStyles.inputInArea}
                                    value={this.state.skipperData.skipper.sexe}
                                    onChange={(event) => {
                                        this.setState({skipperData: {...this.state.skipperData, skipper: {...this.state.skipperData.skipper, sexe: event.target.value}}})
                                    }}
                                >
                                    <option value={0}>Homme</option>
                                    <option value={1}>Femme</option>
                                </select>
                            </div>
                        </div>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Taille de t-shirt</label>
                            <div className={globalStyles.inputArea}>
                                <select
                                    className={globalStyles.inputInArea}
                                    value={this.state.skipperData.skipper.shirtSize}
                                    onChange={(event) => {
                                        this.setState({skipperData: {...this.state.skipperData, skipper: {...this.state.skipperData.skipper, shirtSize: event.target.value}}})

                                    }}
                                >
                                    <option value={0}>XS</option>
                                    <option value={1}>S</option>
                                    <option value={2}>M</option>
                                    <option value={3}>L</option>
                                    <option value={4}>XL</option>
                                    <option value={5}>XXL</option>
                                </select>
                            </div>
                        </div>

                    </div>


                    <div className={styles.inputRow} style={{position: "relative"}}>
                        <div className={styles.choicePart}>
                            <div className={`${globalStyles.radioContainer} ${styles.clickable}`}
                                 onClick={() => {
                                     if(!this.props.regateEventInfos.canColloque) return;
                                     if(this.state.skipperData.skipper.packWe === true && this.state.packCompo[1] === "1") return;
                                     this.updateParticipateIn(1);
                                 }}
                            >
                                <input type={"checkbox"} name={"type"}
                                       checked={partIn[1] === "1"}
                                       disabled={(this.state.skipperData.skipper.packWe === true && this.state.packCompo[1] === "1") || !this.props.regateEventInfos.canColloque}
                                />
                                <span style={{textDecoration: this.props.regateEventInfos.canColloque ? 'auto' : 'line-through'}}>Journée du 21 juin : matinée bien-être et colloque "Voile et Santé" (réservée aux soignants)</span>
                                { this.props.regateEventInfos.canColloque ?
                                    <span className={styles.price}>{this.getPrice(this.props.regateEventInfos.colloquePrice)}</span>
                                    :
                                    <span className={styles.complet}>Complet</span>
                                }
                            </div>
                            <div className={`${globalStyles.radioContainer} ${styles.clickable}`}
                                 onClick={() => {
                                     if(!this.props.regateEventInfos.canDinner) return;
                                     if(this.state.skipperData.skipper.packWe === true && this.state.packCompo[2] === "1") return;
                                     this.updateParticipateIn(2)
                                 }}
                            >
                                <input type={"checkbox"} name={"type"}
                                       checked={partIn[2] === "1"}
                                       disabled={(this.state.skipperData.skipper.packWe === true && this.state.packCompo[2] === "1") || !this.props.regateEventInfos.canDinner}
                                />
                                <span style={{textDecoration: this.props.regateEventInfos.canDinner ? 'auto' : 'line-through'}}>Soirée "GEM en Musique" du vendredi 21 juin à l'UNM</span>
                                { this.props.regateEventInfos.canDinner ?
                                    <span className={styles.price}>{this.getPrice(this.props.regateEventInfos.dinnerPrice)}</span>
                                    :
                                    <span className={styles.complet}>Complet</span>
                                }
                            </div>
                            <div className={`${globalStyles.radioContainer} ${styles.clickable}`}
                                 onClick={() => {
                                     if (!this.props.regateEventInfos.canRegate) return;
                                     if(this.state.skipperData.skipper.packWe === true && this.state.packCompo[0] === "1") return;
                                     this.updateParticipateIn(0)
                                 }}
                            >
                                <input type={"checkbox"} name={"type"}
                                       checked={partIn[0] === "1"}
                                       disabled={(this.state.skipperData.skipper.packWe === true && this.state.packCompo[0] === "1") || !this.props.regateEventInfos.canRegate}
                                />
                                <span style={{textDecoration: this.props.regateEventInfos.canRegate ? 'auto' : 'line-through'}}>2 jours sur l'eau : régate du 22 juin et régate du 23 juin (ne comprend pas la soirée du 21 juin)</span>
                                { this.props.regateEventInfos.canRegate ?
                                    <span className={styles.price}>{this.getPrice(this.props.regateEventInfos.regatePrice)}</span>
                                    :
                                    <span className={styles.complet}>Complet</span>
                                }
                            </div>
                            <div className={`${globalStyles.radioContainer} ${styles.clickable}`}
                                 onClick={() => {
                                     if(!this.props.regateEventInfos.canParty) return;
                                     if(this.state.skipperData.skipper.packWe === true && this.state.packCompo[3] === "1") return;
                                     this.updateParticipateIn(3)
                                 }}
                            >
                                <input type={"checkbox"} name={"type"}
                                       checked={partIn[3] === "1"}
                                       disabled={(this.state.skipperData.skipper.packWe === true && this.state.packCompo[3] === "1") || !this.props.regateEventInfos.canParty}
                                />
                                <span style={{textDecoration: this.props.regateEventInfos.canParty ? 'auto' : 'line-through'}}>Soirée festive du samedi 22 juin à La Nautique</span>
                                { this.props.regateEventInfos.canParty ?
                                    <span className={styles.price}>{this.getPrice(this.props.regateEventInfos.partyPrice)}</span>
                                    :
                                    <span className={styles.complet}>Complet</span>
                                }
                            </div>
                        </div>
                        <div className={styles.divider}>
                            et/ou
                        </div>
                        <div className={styles.choicePart} style={{alignItems: "center"}}>
                            <b style={{textAlign: "center", marginBottom: "10px"}}>PACK Week-end GEM : la 4è édition à un tarif réduit <br/>2 jours sur l'eau + Soirée festive du samedi 22 juin <br/>(ne comprend pas la soirée du vendredi 21 juin ni le colloque)</b>
                            <div className={`${globalStyles.radioContainer} ${styles.clickable}`}
                                 onClick={() => {
                                     if(!this.props.regateEventInfos.canPack) return;
                                     if(!this.state.skipperData.skipper.packWe) {
                                         this.getPack()
                                     }
                                     this.setState({
                                         skipperData: {
                                             ...this.state.skipperData,
                                             skipper: {
                                                 ...this.state.skipperData.skipper,
                                                 packWe: !this.state.skipperData.skipper.packWe
                                             }
                                         }
                                     });
                                 }}
                                 style={{alignItems: "flex-start"}}
                            >
                                <input type={"checkbox"} name={"type"}
                                       checked={this.state.skipperData.skipper.packWe}
                                       style={{marginTop: "4px"}}
                                       disabled={!this.props.regateEventInfos.canPack}
                                />
                                <div style={{display: "flex", gap: "5px", flexDirection: "column"}}>
                                    <div style={{display: "flex", gap: "10px"}}>
                                        <span style={{textDecoration: this.props.regateEventInfos.canPack ? 'auto' : 'line-through'}}>PACK Week-end GEM</span>
                                        { this.props.regateEventInfos.canPack ?
                                            <span className={styles.price}>{this.getPrice(this.props.regateEventInfos.packPrice)}</span>
                                            :
                                            <span className={styles.complet}>Complet</span>
                                        }
                                    </div>
                                    <div style={{
                                        fontSize: "0.8rem",
                                        textDecoration: this.props.regateEventInfos.canPack ? 'auto' : 'line-through'
                                        }}
                                    >
                                        {this.props.regateEventInfos.packCompoString}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>

            </div>
        );
    }
}

export default NoPlaces;
