import React, {Component} from "react"
import globalStyles from "../../styles/globalStyles.module.css"
import styles from "./Question.module.css"

class Question extends Component {

    constructor(props) {
        super(props);
        this.state = {
            answer: this.props.answer
        }
    }



    render () {

        if(this.state.answer === -1) this.setState({answer: this.props.answer})

        return (
            <div className={styles.container}>
                <div className={styles.question}>
                    {this.props.number} / 22 <br/>
                    {this.props.question}
                </div>
                <div style={{display: "flex", alignItems: "center", gap: window.innerWidth > 800 ? "50px" : "5px"}}>

                    <div>
                        <div className={styles.radioContainer}>
                            <input
                                type={"radio"}
                                name={"frequence"}
                                checked={this.state.answer === 0}
                                onChange={() => this.setState({answer: 0})}
                                className={styles.radio}
                                style={{height: window.innerWidth > 800 ? 35 : 25, width: window.innerWidth > 800 ? 35 : 25}}
                            />
                        </div>
                        <div className={styles.answer}>Jamais</div>
                    </div>

                    <div>
                        <div className={styles.radioContainer}>
                            <input
                                type={"radio"}
                                name={"frequence"}
                                className={styles.radio}
                                style={{height: window.innerWidth > 800 ? 42 : 29, width: window.innerWidth > 800 ? 42 : 29}}
                                checked={this.state.answer === 1}
                                onChange={() => this.setState({answer: 1})}
                            />
                        </div>
                        <div className={styles.answer} style={{visibility: window.innerWidth > 800 ? null : "hidden"}}>{window.innerWidth > 800 && "Quelques\nfois par an"}</div>
                    </div>

                    <div>
                        <div className={styles.radioContainer}>
                            <input
                                type={"radio"}
                                name={"frequence"}
                                className={styles.radio}
                                style={{height: window.innerWidth > 800 ? 49 : 33, width: window.innerWidth > 800 ? 49 : 33}}
                                checked={this.state.answer === 2}
                                onChange={() => this.setState({answer: 2})}
                            />
                        </div>
                        <div className={styles.answer} style={{visibility: window.innerWidth > 800 ? null : "hidden"}}>{window.innerWidth > 800 && "Une fois\npar mois"}</div>
                    </div>

                    <div>
                        <div className={styles.radioContainer}>
                            <input
                                type={"radio"}
                                name={"frequence"}
                                className={styles.radio}
                                style={{height: window.innerWidth > 800 ? 56 : 37, width: window.innerWidth > 800 ? 56 : 37}}
                                checked={this.state.answer === 3}
                                onChange={() => this.setState({answer: 3})}
                            />
                        </div>
                        <div className={styles.answer}>Quelques fois<br/>par mois</div>
                    </div>

                    <div>
                        <div className={styles.radioContainer}>
                            <input
                                type={"radio"}
                                name={"frequence"}
                                className={styles.radio}
                                style={{height: window.innerWidth > 800 ? 63 : 41, width: window.innerWidth > 800 ? 63 : 41}}
                                checked={this.state.answer === 4}
                                onChange={() => this.setState({answer: 4})}
                            />
                        </div>
                        <div className={styles.answer} style={{visibility: window.innerWidth > 800 ? null : "hidden"}}>{window.innerWidth > 800 && "Une fois\npar semaine"}</div>
                    </div>

                    <div>
                        <div className={styles.radioContainer}>
                            <input
                                type={"radio"}
                                name={"frequence"}
                                className={styles.radio}
                                style={{height: window.innerWidth > 800 ? 70 : 45, width: window.innerWidth > 800 ? 70 : 45}}
                                checked={this.state.answer === 5}
                                onChange={() => this.setState({answer: 5})}
                            />
                        </div>
                        <div className={styles.answer} style={{visibility: window.innerWidth > 800 ? null : "hidden"}}>{window.innerWidth > 800 && "Quelques fois\npar semaine"}</div>
                    </div>

                    <div>
                        <div className={styles.radioContainer}>
                            <input
                                type={"radio"}
                                name={"frequence"}
                                className={styles.radio}
                                style={{height: window.innerWidth > 800 ? 77 : 49, width: window.innerWidth > 800 ? 77 : 49}}
                                checked={this.state.answer === 6}
                                onChange={() => this.setState({answer: 6})}
                            />
                        </div>
                        <div className={styles.answer}>Chaque jour</div>
                    </div>

                </div>

                <div style={{position: "absolute", bottom: 100, display: "flex", justifyContent: "space-between", width: "min(1200px, 100% - 40px)", gap: "10px"}}>
                    <button
                        className={globalStyles.button}
                        style={{width: "200px"}}
                        onClick={() => {
                            this.props.goPrevious();
                            this.setState({answer: -1});
                        }}
                    >
                        Précédent
                    </button>
                    <button
                        className={this.state.answer === null || this.state.answer === -1 ? globalStyles.disabledButton : globalStyles.button}
                        disabled={this.state.answer === null || this.state.answer === -1}
                        style={{width: "200px"}}
                        onClick={() => {
                            this.props.addAnswer(this.props.number, this.state.answer);
                            this.props.goNext();
                            this.setState({answer: -1});
                        }}
                    >
                        Suivant
                    </button>
                </div>

            </div>
        );
    }
}

export default Question;
