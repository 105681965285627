import React from "react"
import globalStyles from "../../styles/globalStyles.module.css"
import styles from "./ConfirmPay.module.css"
import TemplateComponent, {api_url, mapStateToProps, stripe_pk} from "../../services/APITemplate";
import {connect} from "react-redux";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import SetupForm from "../Utils/SetupForm/SetupForm";
import Empty from "../Utils/Empty/Empty";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAnchor, faCalendarDay, faCreditCard, faGift, faShip, faUser} from "@fortawesome/free-solid-svg-icons";


class ConfirmAndPay extends TemplateComponent {

    constructor(props) {
        super(props);
        this.state = {
            price: null,
            clientSecret: "",
            hasAlreadySetup: false,
            isOK: true,
            donation: 0,
        }
        this.load = {
            price: {
                start: false,
                end: false
            },
            setupIntent: {
                start: false,
                end: false
            }
        }
        this.sending = false;
        this.donation = "";
        this.stripePromise = loadStripe(stripe_pk)
        if(parseInt(window.localStorage.getItem("hasRegateOrder"))) {
            const regState = JSON.parse(window.localStorage.getItem("regateOrderState"))
            this.propsReg = {
                participantData: regState.participantData,
                skipperData: regState.skipperData,
                isSkipper: regState.skipper,
                regateEvent: regState.regateEvents[regState.regateEvent],
            }
        }
        if(!parseInt(window.localStorage.getItem("hasRegateOrder")) || this.props.id !== this.propsReg.regateEvent.id) {
            this.goError()
        }
    }

    getBinary(n){
        let binary = n.toString(2).split('').reverse()
        while(binary.length < 4) {
            binary.push("0")
        }
        return binary
    }

    loadData() {
        this.loadSetupIntent();
        this.loadBackPrice();
    }

    loadSetupIntent(){
        if(!this.load.setupIntent.start) {
            this.load.setupIntent.start = true
            let request = new Request(api_url + "/pay/setup/create", {method: "GET", credentials: "include"});
            this.fetchResponseOrLoginOrError(request, true).then((response) => {
                return response.json()
            }).then((data) => {
                this.load.setupIntent.end = true
                if(data.hasAlready && !this.props.user.user.cardLastFour) {
                    this.mustSecure = true
                    this.props.dispatch({type: "CHANGED"})
                }
                this.setState({clientSecret: data.clientSecret, hasAlreadySetup: data.hasAlready});
            })
        }
    }

    loadBackPrice() {
        if(!this.load.price.start) {
            this.load.price.start = true
            let data = new FormData();
            let infos = {
                isSkipper: this.propsReg.isSkipper,
                partData: this.propsReg.participantData,
                skippData: this.propsReg.skipperData,
            }
            data.append("json", JSON.stringify(infos))
            let request = new Request(api_url + "/regate/price/get/" + this.propsReg.regateEvent.id, {method: "POST", body: data, credentials: "include"});
            this.fetchResponseOrLoginOrError(request, true).then((response) => {
                return response.json()
            }).then((data) => {
                this.load.price.end = true
                this.setState({price: data.price});
            })
        }
    }

    registerRegate() {
        if(!this.sending) {
            this.sending = true
            this.forceUpdate()
            let data = new FormData();
            let infos = {
                isSkipper: this.propsReg.isSkipper,
                partData: this.propsReg.participantData,
                skippData: this.propsReg.skipperData,
                donation: this.state.donation
            }
            data.append("json", JSON.stringify(infos))
            if(this.propsReg.isSkipper) {
                let base64I = window.localStorage.getItem("insurance");
                data.append("insurance", base64I)
                if(window.localStorage.getItem("jauge")){
                    let base64J = window.localStorage.getItem("jauge");
                    data.append("jauge", base64J)
                }
            }
            let request = new Request(api_url + "/regate/register/mixed/" + this.propsReg.regateEvent.id, {method: "POST", body: data, credentials: "include"});
            this.fetchResponseOrLoginOrError(request, true).then((response) => {
                return response.json()
            }).then((data) => {
                if(data.success) {
                    window.localStorage.removeItem("hasRegateOrder");
                    window.localStorage.removeItem("regateOrderState");
                    window.localStorage.removeItem("insurance");
                    window.localStorage.removeItem("jauge");
                    if(data.redirectUrl) {
                        this.setState({shouldNavigate: true, navigateTo: data.redirectUrl})
                    }
                    else {
                        this.addFlash("success", "Votre inscription a bien été prise en compte.")
                        setTimeout( () => {this.setState({shouldNavigate: true, navigateTo: "/myregate/" + data.id.toString()})}, 100)
                    }
                }
                this.sending = false
                this.forceUpdate()
            })
        }
    }

    prev() {
        this.setState({shouldNavigate: true, navigateTo: "/regate"})
    }

    renderCrew() {
        let res = [];
        for(let i in this.propsReg.skipperData.crew) {
            res.push(
                <div className={styles.lineContainer}>
                    <div className={styles.icon}>
                        <FontAwesomeIcon icon={faUser}/>
                    </div>
                    <div className={styles.text}>
                        <b>{this.propsReg.skipperData.crew[i].firstName} {this.propsReg.skipperData.crew[i].lastName}</b>
                        {this.renderParticipateIn(this.propsReg.skipperData.crew[i].participateIn)}
                    </div>
                </div>
            )
        }
        return res
    }

    renderParticipateIn(p) {
        let participateIn = this.getBinary(p);
        let packCompo = this.getBinary(this.propsReg.regateEvent.packCompo);
        let isPack = (p & this.propsReg.regateEvent.packCompo) === this.propsReg.regateEvent.packCompo;

        return (
            <div>
                { participateIn[1] === "1" &&
                    <div className={styles.normal}>Journée du 21 juin : matinée bien-être et colloque "Voile et Santé" (réservée aux soignants){isPack && packCompo[1] === "1" && <span> (Pack week-end)</span>}</div>
                }
                { participateIn[2] === "1" &&
                    <div className={styles.normal}>Dîner/soirée du vendredi{isPack && packCompo[2] === "1" && <span> (Pack week-end)</span>}</div>
                }
                { participateIn[0] === "1" &&
                    <div className={styles.normal}>2 jours sur l'eau : régate du 22 et régate du 23 juin (ne comprend pas la soirée du 21 juin){isPack && packCompo[0] === "1" && <span> (Pack week-end)</span>}</div>
                }
                { participateIn[3] === "1" &&
                    <div className={styles.normal}>Soirée festive du samedi 22 juin à La Nautique{isPack && packCompo[3] === "1" && <span> (Pack week-end)</span>}</div>
                }
            </div>
        )
    }

    getStripeFrais() {
        return Math.ceil(this.state.price*(1.5/100)) + 25;
    }

    render () {
        if(!this.canRender()) {
            return this.noRender()
        }
        return (
            <div>
                {this.head()}
                {this.flashtoDisplay}

                <div className={`${globalStyles.mainContainer} ${styles.mainContainer}`}>
                    <div className={styles.content} style={{overflow: 'auto', paddingBottom: 100, width: "100%"}}>
                        <h1>Valider et payer</h1>

                        <div className={styles.card}>
                            <div className={styles.area}>
                                <div style={{flex: 1, display: "flex", flexDirection: "column", width: "100%"}}>
                                    <div
                                        className={styles.contentContainer}
                                        style={{
                                            background: "#fafafa",
                                            boxShadow: "0 4px 10px 1px rgb(0 0 0 / 25%)",
                                            alignSelf: "stretch",
                                        }}
                                    >
                                        <h2 style={{marginBottom: "15px"}}>Récapitulatif</h2>
                                        { this.propsReg.isSkipper ?
                                            // skipper
                                            <div style={{display: "flex", gap: 20, flexDirection: "column"}}>
                                                <div className={styles.lineContainer}>
                                                    <div className={styles.icon}>
                                                        <FontAwesomeIcon icon={faUser}/>
                                                    </div>
                                                    <div className={styles.text}>
                                                        <b>{this.propsReg.skipperData.skipper.firstName} {this.propsReg.skipperData.skipper.lastName}</b>
                                                        {this.renderParticipateIn(this.propsReg.skipperData.skipper.participateIn)}
                                                    </div>
                                                </div>
                                                {this.renderCrew()}
                                                <div className={styles.lineContainer}>
                                                    <div className={styles.icon}>
                                                        <FontAwesomeIcon icon={faAnchor}/>
                                                    </div>
                                                    <div className={styles.text}>
                                                        <b>{this.propsReg.skipperData.boat.name}</b>
                                                        <div>Inscription à la régate</div>
                                                    </div>
                                                </div>
                                                { this.state.price > 0 &&
                                                    <div className={styles.lineContainer}>
                                                        <div className={styles.icon}>
                                                            <FontAwesomeIcon icon={faCreditCard}/>
                                                        </div>
                                                        <div className={styles.text}>
                                                            <div>Frais de paiement : {(this.getStripeFrais()/100).toFixed(2)} €</div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className={styles.lineContainer}>
                                                    <div className={styles.icon}>
                                                        <FontAwesomeIcon icon={faGift}/>
                                                    </div>
                                                    <div className={styles.text}>
                                                        <div>Don à Guérir en mer : {(this.state.donation/100).toFixed(2)} €</div>
                                                    </div>
                                                </div>
                                                <div className={styles.price}>
                                                    Total : {((this.state.price+this.state.donation)/100).toFixed(2)} €
                                                </div>
                                            </div>
                                            :
                                            // participant
                                            <div style={{display: "flex", gap: 20, flexDirection: "column"}}>
                                                <div className={styles.lineContainer}>
                                                    <div className={styles.icon}>
                                                        <FontAwesomeIcon icon={faUser}/>
                                                    </div>
                                                    <div className={styles.text}>
                                                        <b>{this.propsReg.participantData.firstName} {this.propsReg.participantData.lastName}</b>
                                                        {this.renderParticipateIn(this.propsReg.participantData.participateIn)}
                                                    </div>
                                                </div>
                                                { this.state.price > 0 &&
                                                    <div className={styles.lineContainer}>
                                                        <div className={styles.icon}>
                                                            <FontAwesomeIcon icon={faCreditCard}/>
                                                        </div>
                                                        <div className={styles.text}>
                                                            <div>Frais de paiement : {(this.getStripeFrais()/100).toFixed(2)} €</div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className={styles.lineContainer}>
                                                    <div className={styles.icon}>
                                                        <FontAwesomeIcon icon={faGift}/>
                                                    </div>
                                                    <div className={styles.text}>
                                                        <div>Don à Guérir en mer : {(this.state.donation/100).toFixed(2)} €</div>
                                                    </div>
                                                </div>
                                                <div className={styles.price}>
                                                    Total : {((this.state.price+this.state.donation)/100).toFixed(2)} €
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div style={{marginTop: 20}}>
                                        <h2 style={{marginBottom: "15px"}}>Faire un don</h2>
                                        <div>
                                            Vous pouvez ajouter au total à payer un don à l'association Guérir en mer.
                                        </div>
                                        <div className={styles.don}>
                                            <div className={globalStyles.inputContainer} style={{flex: 1}}>
                                                <label className={globalStyles.label}>Don (€)</label>
                                                <input type={"text"} name={"donation"} className={globalStyles.input}
                                                       value={this.donation}
                                                       onChange={(event) => {
                                                           this.donation = event.target.value
                                                           this.forceUpdate()
                                                       }}
                                                />
                                            </div>
                                            <div style={{flex: 1, display: "flex", justifyContent:"center", alignItems:"flex-end", padding: 10}}>
                                                <button onClick={() => {this.setState({donation: this.donation === "" ? 0 : Math.max(0, Math.ceil(parseFloat(this.donation.replace(',', '.'))*100))})}} className={globalStyles.button} disabled={this.sending}>
                                                    Enregistrer
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{flex: 1}}>
                                    {(this.state.price+this.state.donation) > 0 ?
                                      this.state.hasAlreadySetup ?
                                        <div>
                                            Votre moyen de paiement est bien configuré : **** **** **** {this.props.user.user.cardLastFour}<br/>
                                            En cliquant sur le bouton <b>Valider et payer</b>,
                                            vous vous inscrivez à l'événement et payez  {((this.state.price+this.state.donation)/100).toFixed(2)}€ (dont {(this.state.donation/100).toFixed(2)}€ de don à Guérir en mer)<br/>
                                            En cas de problème ou de question, vous pourrez nous contacter.<br/><br/>

                                            Vous recevrez un e-mail de confirmation pour votre inscription.<br/>

                                            <div className={styles.buttonContainer}>
                                                <button onClick={() => this.registerRegate()} className={globalStyles.button} disabled={this.sending}>
                                                    Valider et payer {((this.state.price+this.state.donation)/100).toFixed(2)}€
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        this.load.setupIntent.end &&
                                        <div style={{margin: "10px"}}>
                                            <div style={{marginBottom: "10px"}}>
                                                Pour vous inscrire à la régate, vous devez d'abord enregistrer
                                                un moyen de paiement :
                                            </div>
                                            <Elements stripe={this.stripePromise} options={{
                                                appearance: {theme: "stripe"},
                                                clientSecret: this.state.clientSecret
                                            }}>
                                                <SetupForm id={this.props.id} isEvent={false} isRegate={true}/>
                                            </Elements>
                                        </div>
                                    :
                                        <div>
                                            En cliquant sur le bouton <b>Valider</b>, vous vous inscrivez à l'événement.
                                            En cas de problème ou de question, vous pourrez nous contacter.<br/><br/>

                                            Vous recevrez un e-mail de confirmation pour votre inscription.<br/>

                                            <div className={styles.buttonContainer}>
                                                <button onClick={() => this.registerRegate()} className={globalStyles.button} disabled={this.sending}>
                                                    Valider
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{position: "absolute", bottom: 100, display: "flex", justifyContent: "space-between", maxWidth: "1200px", width: "100%"}}>
                        <button
                            className={globalStyles.button}
                            style={{width: "200px", visibility: "visible"}}
                            onClick={this.prev.bind(this)}
                        >
                            Précédent
                        </button>
                        <button
                            className={globalStyles.button}
                            style={{width: "200px", visibility: "hidden"}}
                        >
                            Suivant
                        </button>
                    </div>
                </div>

                {this.foot()}
            </div>
        );
    }
}

export default connect(mapStateToProps)(ConfirmAndPay);
