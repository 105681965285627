import React from "react"
import globalStyles from "../../styles/globalStyles.module.css"
import styles from "./Regate.module.css"
import TemplateComponent from "../../services/APITemplate";


class NoPlaces2 extends TemplateComponent {

    constructor(props) {
        super(props);
        this.state = {
            skipperData: this.props.skipperData,
            packCompo: this.getBinary(this.props.regateEventInfos.packCompo),
            nb: this.props.skipperData.crew.length,
            canGoNext: false
        }
    }

    getBinary(n){
        let binary = n.toString(2).split('').reverse()
        while(binary.length < 4) {
            binary.push("0")
        }
        return binary
    }

    getInfos() {
        return this.state.skipperData;
    }

    getPrice(price){
        if(price === 0) return "Gratuit";
        return price.toFixed(2) + " €";
    }

    getPack(index) {
        let partIn = [...this.state.skipperData.crew[index].participateIn.toString(2).split('')].reverse()
        while(partIn.length < 4) {
            partIn.push("0")
        }
        for(let i = 0; i < this.state.packCompo.length; i++) {
            if(this.state.packCompo[i] === "1") {
                partIn[i] = "1";
            }
        }
        this.state.skipperData.crew[index].participateIn = parseInt([...partIn].reverse().join(''), 2);
        this.forceUpdate();

    }


    updateParticipateIn(index, i) {
        let partIn = [...this.state.skipperData.crew[index].participateIn.toString(2).split('')].reverse()
        while(partIn.length < 4) {
            partIn.push("0")
        }
        partIn[i] = partIn[i] === "0" ? "1" : "0";

        this.state.skipperData.crew[index].participateIn = parseInt([...partIn].reverse().join(''), 2);
        this.forceUpdate();

    }

    getParticipateInString(nb) {
        let partIn = nb.toString(2).split('').reverse()
        let partInString = [];
        if(partIn[1] === "1") {
            partInString.push("Journée du 30 juin : matinée bien-être et colloque \"Voile et Santé\" (réservée aux soignants)")
        }
        if(partIn[2] === "1") {
            partInString.push("Soirée \"GEM GEM\" du vendredi 30 juin à l'UNM")
        }
        if(partIn[0] === "1") {
            partInString.push("2 jours sur l'eau : rallye du 1er juillet et régate du 2 juillet (ne comprend pas la soirée du 1er juillet)")
        }
        if(partIn[3] === "1") {
            partInString.push("Soirée festive du samedi 1er juillet à La Nautique")
        }

        return partInString.join(", ");
    }

    getSizeString(nb) {
        switch (nb) {
            case 0:
                return "XS";
            case 1:
                return "S";
            case 2:
                return "M";
            case 3:
                return "L";
            case 4:
                return "XL";
            case 5:
                return "XXL";
            default:
                return "";
        }
    }

    getLevelString(nb) {
        if(nb == 0) return "Je n'ai jamais fait de voile";
        if(nb == 1) return "J'ai des bases en voile";
        if(nb == 2) return "J'ai des compétences en voile";
        if(nb == 3) return "J'ai déjà regaté";
        if(nb == 4) return "Je suis régatier confirmé";

        return "";
    }

    getValueOrNull(value) {
        if(value === null || value === "") return "Non renseigné";
        return value;
    }

    getMembersForm() {
        let members = [];
        for(let i = 0; i < this.state.nb; i++) {
            members.push(
                <div>
                    <h2>Membre {i + 1}</h2>

                    <div className={styles.inputRow}>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Nom</label>
                            <input type={"text"} name={"lastName"} className={globalStyles.input}
                                   value={this.state.skipperData.crew[i].lastName}
                                   onChange={(event) => {
                                       this.state.skipperData.crew[i].lastName = event.target.value;
                                       this.forceUpdate();
                                   }}
                            />
                        </div>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Prénom</label>
                            <input type={"text"} name={"firstName"} className={globalStyles.input}
                                   value={this.state.skipperData.crew[i].firstName}
                                   onChange={(event) => {
                                       this.state.skipperData.crew[i].firstName = event.target.value;
                                       this.forceUpdate();
                                   }}
                            />
                        </div>
                    </div>


                    <div className={styles.inputRow}>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Numéro de licence FFV</label>
                            <input
                                type={"text"} name={"ffv"} className={globalStyles.input}
                                value={this.state.skipperData.crew[i].ffv}
                                onChange={(event) => {
                                    this.state.skipperData.crew[i].ffv = event.target.value;
                                    this.forceUpdate();
                                }}
                            />
                        </div>

                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Niveau</label>
                            <div className={globalStyles.inputArea}>
                                <select
                                    className={globalStyles.inputInArea}
                                    value={this.state.skipperData.crew[i].sailingLevel}
                                    onChange={(event) => {
                                        this.state.skipperData.crew[i].sailingLevel = event.target.value;
                                        this.forceUpdate();
                                    }}
                                >
                                    <option value={0}>Je n'ai jamais fait de voile</option>
                                    <option value={1}>J'ai des bases en voile</option>
                                    <option value={2}>J'ai des compétences en voile</option>
                                    <option value={3}>J'ai déjà regaté</option>
                                    <option value={4}>Je suis regatier confirmé</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className={styles.inputRow}>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Sexe</label>
                            <div className={globalStyles.inputArea}>
                                <select
                                    className={globalStyles.inputInArea}
                                    value={this.state.skipperData.crew[i].sexe}
                                    onChange={(event) => {
                                        this.state.skipperData.crew[i].sexe = event.target.value;
                                        this.forceUpdate();
                                    }}
                                >
                                    <option value={0}>Homme</option>
                                    <option value={1}>Femme</option>
                                </select>
                            </div>
                        </div>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Taille de t-shirt</label>
                            <div className={globalStyles.inputArea}>
                                <select
                                    className={globalStyles.inputInArea}
                                    value={this.state.skipperData.crew[i].shirtSize}
                                    onChange={(event) => {
                                        this.state.skipperData.crew[i].shirtSize = event.target.value;
                                        this.forceUpdate();
                                    }}
                                >
                                    <option value={0}>XS</option>
                                    <option value={1}>S</option>
                                    <option value={2}>M</option>
                                    <option value={3}>L</option>
                                    <option value={4}>XL</option>
                                    <option value={5}>XXL</option>
                                </select>
                            </div>
                        </div>


                    </div>

                    <div className={styles.inputRow} style={{position: "relative"}}>
                        <div className={styles.choicePart}>
                            <div className={`${globalStyles.radioContainer} ${styles.clickable}`}
                                 onClick={() => {
                                     if(!this.props.regateEventInfos.canColloque) return;
                                     if(this.state.skipperData.crew[i].packWe === true && this.state.packCompo[1] === "1") return;
                                     this.updateParticipateIn(i, 1);
                                 }}
                            >
                                <input type={"checkbox"} name={"type"}
                                       checked={this.getPartIn(this.state.skipperData.crew[i].participateIn)[1] === "1"}
                                       disabled={(this.state.skipperData.crew[i].packWe === true && this.state.packCompo[1] === "1") || !this.props.regateEventInfos.canColloque}
                                />
                                <span style={{textDecoration: this.props.regateEventInfos.canColloque ? 'auto' : 'line-through'}}>Journée du 30 juin : matinée bien-être et colloque "Voile et Santé" (réservée aux soignants)</span>
                                { this.props.regateEventInfos.canColloque ?
                                    <span className={styles.price}>{this.getPrice(this.props.regateEventInfos.colloquePrice)}</span>
                                    :
                                    <span className={styles.complet}>Complet</span>
                                }
                            </div>
                            <div className={`${globalStyles.radioContainer} ${styles.clickable}`}
                                 onClick={() => {
                                     if(!this.props.regateEventInfos.canDinner) return;
                                     if(this.state.skipperData.crew[i].packWe === true && this.state.packCompo[2] === "1") return;
                                     this.updateParticipateIn(i, 2)
                                 }}
                            >
                                <input type={"checkbox"} name={"type"}
                                       checked={this.getPartIn(this.state.skipperData.crew[i].participateIn)[2] === "1"}
                                       disabled={(this.state.skipperData.crew[i].packWe === true && this.state.packCompo[2] === "1") || !this.props.regateEventInfos.canDinner}
                                />
                                <span style={{textDecoration: this.props.regateEventInfos.canDinner ? 'auto' : 'line-through'}}>Soirée "GEM GEM" du vendredi 30 juin à l'UNM</span>
                                { this.props.regateEventInfos.canDinner ?
                                    <span className={styles.price}>{this.getPrice(this.props.regateEventInfos.dinnerPrice)}</span>
                                    :
                                    <span className={styles.complet}>Complet</span>
                                }
                            </div>
                            <div className={`${globalStyles.radioContainer} ${styles.clickable}`}
                                 onClick={() => {
                                     if (!this.props.regateEventInfos.canRegate) return;
                                     if(this.state.skipperData.crew[i].packWe === true && this.state.packCompo[0] === "1") return;
                                     this.updateParticipateIn(i, 0)
                                 }}
                            >
                                <input type={"checkbox"} name={"type"}
                                       checked={this.getPartIn(this.state.skipperData.crew[i].participateIn)[0] === "1"}
                                       disabled={(this.state.skipperData.crew[i].packWe === true && this.state.packCompo[0] === "1") || !this.props.regateEventInfos.canRegate}
                                />
                                <span style={{textDecoration: this.props.regateEventInfos.canRegate ? 'auto' : 'line-through'}}>2 jours sur l'eau : rallye du 1er juillet et régate du 2 juillet (ne comprend pas la soirée du 1er juillet)</span>
                                { this.props.regateEventInfos.canRegate ?
                                    <span className={styles.price}>{this.getPrice(this.props.regateEventInfos.regatePrice)}</span>
                                    :
                                    <span className={styles.complet}>Complet</span>
                                }
                            </div>
                            <div className={`${globalStyles.radioContainer} ${styles.clickable}`}
                                 onClick={() => {
                                     if(!this.props.regateEventInfos.canParty) return;
                                     if(this.state.skipperData.crew[i].packWe === true && this.state.packCompo[3] === "1") return;
                                     this.updateParticipateIn(i, 3)
                                 }}
                            >
                                <input type={"checkbox"} name={"type"}
                                       checked={this.getPartIn(this.state.skipperData.crew[i].participateIn)[3] === "1"}
                                       disabled={(this.state.skipperData.crew[i].packWe === true && this.state.packCompo[3] === "1") || !this.props.regateEventInfos.canParty}
                                />
                                <span style={{textDecoration: this.props.regateEventInfos.canParty ? 'auto' : 'line-through'}}>Soirée festive du samedi 1er juillet à La Nautique</span>
                                { this.props.regateEventInfos.canParty ?
                                    <span className={styles.price}>{this.getPrice(this.props.regateEventInfos.partyPrice)}</span>
                                    :
                                    <span className={styles.complet}>Complet</span>
                                }
                            </div>
                        </div>
                        <div className={styles.divider}>
                            et/ou
                        </div>
                        <div className={styles.choicePart} style={{alignItems: "center"}}>
                            <b style={{textAlign: "center", marginBottom: "10px"}}>PACK Week-end GEM : la 3è édition à un tarif réduit <br/>2 jours sur l'eau + Soirée festive du samedi 1er juillet <br/>(ne comprend pas la soirée du vendredi 30 juin ni le colloque)</b>
                            <div className={`${globalStyles.radioContainer} ${styles.clickable}`}
                                 onClick={() => {
                                     if(!this.props.regateEventInfos.canPack) return;
                                     if(!this.state.skipperData.crew[i].packWe) {
                                         this.getPack(i);
                                     }
                                     this.state.skipperData.crew[i].packWe = !this.state.skipperData.crew[i].packWe;
                                     this.forceUpdate();
                                 }}
                                 style={{alignItems: "flex-start"}}
                            >
                                <input type={"checkbox"} name={"type"}
                                       checked={this.state.skipperData.crew[i].packWe}
                                       style={{marginTop: "4px"}}
                                       disabled={!this.props.regateEventInfos.canPack}
                                />
                                <div style={{display: "flex", gap: "5px", flexDirection: "column"}}>
                                    <div style={{display: "flex", gap: "10px"}}>
                                        <span style={{textDecoration: this.props.regateEventInfos.canPack ? 'auto' : 'line-through'}}>PACK Week-end GEM</span>
                                        { this.props.regateEventInfos.canPack ?
                                            <span className={styles.price}>{this.getPrice(this.props.regateEventInfos.packPrice)}</span>
                                            :
                                            <span className={styles.complet}>Complet</span>
                                        }
                                    </div>
                                    <div style={{
                                        fontSize: "0.8rem",
                                        textDecoration: this.props.regateEventInfos.canPack ? 'auto' : 'line-through'
                                        }}
                                    >
                                        {this.props.regateEventInfos.packCompoString}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>
            )
        }
        return members;
    }

    addMember() {
        this.state.skipperData.crew.push({
            lastName: "",
            firstName: "",
            sexe: 0,
            shirtSize: 2,
            ffv: "",
            participateIn: 0,
            packWe: false,
            sailingLevel: 0
        })

        this.state.nb++;

        this.forceUpdate();
    }

    deleteMember() {
        this.state.skipperData.crew.pop();
        this.state.nb--;
        this.forceUpdate();
    }

    getPartIn(nb) {
        let partIn = nb.toString(2).split('').reverse()
        while(partIn.length < 4) {
            partIn.push("0")
        }
        return partIn;
    }

    canGoNext() {
        let can = true;
        for(let crew in this.state.skipperData.crew){
            if(this.state.skipperData.crew[crew].participateIn === 0) {
                can = false;
            }
        }
        if(can !== this.props.currentCanNext) {
            this.props.canNext(can)
        }
    }


    render () {
        this.canGoNext()

        return (
            <div style={{width: "100%"}}>

                <div className={styles.content} style={{overflow: 'auto', paddingBottom: 100, fontWeight: "unset"}}>
                    <h1>Inscription de l'équipage</h1>

                    <h2>Skipper</h2>

                    <div>
                        <div className={styles.row}>
                            <div style={{flex: 1}}>
                                <b>Nom :</b> {this.state.skipperData.skipper.lastName}
                            </div>
                            <div style={{flex: 1}}>
                                <b>Prénom :</b> {this.state.skipperData.skipper.firstName}
                            </div>
                        </div>

                        <div className={styles.row}>
                            <div style={{flex: 1}}>
                                <b>Adresse e-mail :</b> {this.getValueOrNull(this.state.skipperData.skipper.email)}
                            </div>
                            <div style={{flex: 1}}>
                                <b>Téléphone :</b> {this.getValueOrNull(this.state.skipperData.skipper.phone)}
                            </div>
                        </div>

                        <div className={styles.row}>
                            <div style={{flex: 1}}>
                                <b>Taille :</b> {this.getSizeString(this.state.skipperData.skipper.shirtSize)}
                            </div>
                            <div style={{flex: 1}}>
                                <b>Genre :</b> {this.state.skipperData.skipper.sexe === 0 ? "M" : "F"}
                            </div>
                        </div>

                        <div className={styles.row}>
                            <div style={{flex: 1}}>
                                <b>Licence FFV :</b> {this.getValueOrNull(this.state.skipperData.skipper.ffv)}
                            </div>
                            <div style={{flex: 1}}>
                                <b>Niveau :</b> {this.getLevelString(this.state.skipperData.skipper.sailingLevel)}
                            </div>
                        </div>
                        <div className={styles.row}>
                            <div style={{flex: 1}}>
                                <b>Participe à :</b> {this.getParticipateInString(this.state.skipperData.skipper.participateIn)}
                            </div>
                        </div>
                    </div>


                    {this.getMembersForm()}

                    <div style={{display: "flex", alignItems: "row", justifyContent: "space-between"}}>
                        {this.state.nb > 0 &&
                            <button
                                className={globalStyles.button}
                                style={{width: "200px", backgroundColor: "var(--red)"}}
                                onClick={this.deleteMember.bind(this)}
                            >
                                Supprimer un membre
                            </button>
                        }

                        <button
                            className={globalStyles.button}
                            style={{width: "200px"}}
                            onClick={this.addMember.bind(this)}
                        >
                            Ajouter un membre
                        </button>
                    </div>


                </div>

            </div>
        );
    }
}

export default NoPlaces2;
