import React from "react"
import globalStyles from "../../styles/globalStyles.module.css"
import styles from "./Regate.module.css"
import TemplateComponent from "../../services/APITemplate";


class AvailablePlaces extends TemplateComponent {

    constructor(props) {
        super(props);
        this.state = {
            skipperWithPlacesData: this.props.skipperWithPlacesData,
            packCompo: this.getBinary(this.props.regateEventInfos.packCompo),
            canGoNext: false,
        }
    }

    getBinary(n){
        let binary = n.toString(2).split('').reverse()
        while(binary.length < 4) {
            binary.push("0")
        }
        return binary
    }

    getInfos() {
        return this.state.skipperWithPlacesData;
    }

    getPrice(price){
        if(price === 0) return "Gratuit";
        return price.toFixed(2) + " €";
    }

    updateParticipateIn(i) {
        let partIn = [...this.state.skipperWithPlacesData.skipper.participateIn.toString(2).split('')].reverse()
        while(partIn.length < 4) {
            partIn.push("0")
        }
        partIn[i] = partIn[i] === "0" ? "1" : "0";

        this.setState({skipperWithPlacesData: {...this.state.skipperWithPlacesData, skipper: {...this.state.skipperWithPlacesData.skipper, participateIn: parseInt([...partIn.reverse()].join(''), 2)}}})

    }

    canGoNext() {
        let can =  this.state.skipperWithPlacesData.boat.name !== "" &&
            this.state.skipperWithPlacesData.boat.length !== "" &&
            this.state.skipperWithPlacesData.boat.model !== "" &&
            this.state.skipperWithPlacesData.boat.nbPlaces > 0 &&
            this.state.skipperWithPlacesData.boat.insurance !== null &&
            this.state.skipperWithPlacesData.nbOpenPlaces > 0 &&
            this.state.skipperWithPlacesData.skipper.lastName !== "" &&
            this.state.skipperWithPlacesData.skipper.firstName !== "" &&
            this.state.skipperWithPlacesData.skipper.email !== "" &&
            this.state.skipperWithPlacesData.skipper.phone !== "";
        if(can !== this.props.currentCanNext) {
            this.props.canNext(can)
        }
    }


    render () {

        this.canGoNext()

        let partIn = this.state.skipperWithPlacesData.skipper.participateIn.toString(2).split('').reverse()
        while(partIn.length < 4) {
            partIn.push("0")
        }

        return (
            <div style={{width: "100%"}}>

                <div className={styles.content} style={{overflow: 'auto', paddingBottom: 100}}>
                    <h1>Inscription</h1>

                    <h2>Information du bateau</h2>

                    <div className={styles.inputRow} style={{position: "relative"}}>
                        <div className={styles.choicePart} style={{padding: 0}}>
                            <div className={`${globalStyles.radioContainer} ${styles.clickable}`}
                                 style={{alignItems: "flex-start"}}
                            >
                                <div style={{display: "flex", gap: "5px", flexDirection: "column"}}>
                                    <div style={{display: "flex", gap: "10px"}}>
                                        <span>Inscription du bateau</span>
                                        <span className={styles.price}>
                                            <span style={{textDecoration: "line-through"}}>{this.getPrice(this.props.regateEventInfos.boatPrice)}</span> Offert
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.inputRow}>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Nom du bateau*</label>
                            <input type={"text"} name={"name"} className={globalStyles.input}
                                   value={this.state.skipperWithPlacesData.boat.name}
                                   onChange={(event) => {
                                       this.setState({skipperWithPlacesData: {...this.state.skipperWithPlacesData, boat: {...this.state.skipperWithPlacesData.boat, name: event.target.value}}})

                                   }}
                            />
                        </div>
                    </div>

                    <div className={styles.inputRow}>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Marque/modèle*</label>
                            <input type={"text"} name={"model"} className={globalStyles.input}
                                   value={this.state.skipperWithPlacesData.boat.model}
                                   onChange={(event) => {
                                       this.setState({skipperWithPlacesData: {...this.state.skipperWithPlacesData, boat: {...this.state.skipperWithPlacesData.boat, model: event.target.value}}})

                                   }}
                            />
                        </div>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Longueur (en m)</label>
                            <input type={"text"} name={"length"} className={globalStyles.input}
                                   value={this.state.skipperWithPlacesData.boat.length}
                                   onChange={(event) => {
                                       this.setState({skipperWithPlacesData: {...this.state.skipperWithPlacesData, boat: {...this.state.skipperWithPlacesData.boat, length: event.target.value}}})

                                   }}
                            />
                        </div>
                    </div>

                    <div className={styles.inputRow}>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Assurance* (fichier pdf)</label>
                            <input type={"file"} name={"insurance"} className={globalStyles.input}
                                   onChange={(event) => {
                                       this.setState({skipperWithPlacesData: {...this.state.skipperWithPlacesData, boat: {...this.state.skipperWithPlacesData.boat, insurance: event.target.files[0]}}})
                                       let reader = new FileReader()
                                       reader.onloadend = function(ev) {
                                           window.localStorage.setItem("insurance", this.result);
                                       }
                                       reader.readAsDataURL(event.target.files[0]);
                                   }}
                            />
                        </div>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Certificat de jauge (fichier pdf)</label>
                            <input type={"file"} name={"jauge"} className={globalStyles.input}
                                   onChange={(event) => {
                                       this.setState({skipperWithPlacesData: {...this.state.skipperWithPlacesData, boat: {...this.state.skipperWithPlacesData.boat, jauge: event.target.files[0]}}})
                                       let reader = new FileReader()
                                       reader.onloadend = function(ev) {
                                           window.localStorage.setItem("jauge", this.result);
                                       }
                                       reader.readAsDataURL(event.target.files[0]);
                                   }}
                            />
                        </div>
                    </div>

                    <div className={styles.inputRow}>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Nombre de places total*</label>
                            <input type={"text"} name={"nbPlaces"} className={globalStyles.input}
                                   value={this.state.skipperWithPlacesData.boat.nbPlaces}
                                   onChange={(event) => {
                                       this.setState({skipperWithPlacesData: {...this.state.skipperWithPlacesData, boat: {...this.state.skipperWithPlacesData.boat, nbPlaces: event.target.value}}})

                                   }}
                            />
                        </div>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Nombre de participants que vous acceptez d'accueillir*</label>
                            <input type={"text"} name={"availablePlaces"} className={globalStyles.input}
                                   value={this.state.skipperWithPlacesData.nbOpenPlaces}
                                   onChange={(event) => {
                                       this.setState({skipperWithPlacesData: {...this.state.skipperWithPlacesData, nbOpenPlaces: event.target.value}})

                                   }}
                            />
                        </div>
                    </div>

                    <div className={styles.infoDiv}>
                        <b>
                            En acceptant d’accueillir gracieusement des participants à bord de votre bateau lors des 2 jours sur l'eau de l'événement, l’association Guérir en mer offre l’inscription du bateau et celle du skipper, ainsi que d’un équipier toutes les 2 personnes accueillies. Le nombre total de personnes à bord doit être permis à l’homologation/l’armement du bateau.
                        </b>

                        <div className={styles.speech}>
                            <div style={{flex: 1, gap: "10px"}}>
                                <b>Exemple 1 :</b> <br/>
                                Vous acceptez d’accueillir 4 personnes à bord. Les frais d’inscription du bateau et celles du skipper (PACK Week-end GEM) sont offerts. En outre, 2 équipiers de votre choix participeront gratuitement (Pack Week-end). Au-delà, les équipiers supplémentaires devront payer leurs frais d'inscription.
                            </div>
                            <div style={{flex: 1}}>
                                <b>Exemple 2 :</b> <br/>
                                Vous acceptez d’accueillir 3 personnes à bord. Les frais d’inscription du bateau et celles du skipper (PACK Week-end GEM) sont offerts. En outre, 1 équipier de votre choix pourra participer gratuitement. Au-delà, les équipiers devront payer leurs frais d'inscription.
                            </div>
                        </div>
                    </div>

                    <h2>Information du skipper</h2>


                    <div className={styles.inputRow}>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Nom*</label>
                            <input type={"text"} name={"lastName"} className={globalStyles.input}
                                   value={this.state.skipperWithPlacesData.skipper.lastName}
                                   onChange={(event) => {
                                       this.setState({skipperWithPlacesData: {...this.state.skipperWithPlacesData, skipper: {...this.state.skipperWithPlacesData.skipper, lastName: event.target.value}}})

                                   }}
                            />
                        </div>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Prénom*</label>
                            <input type={"text"} name={"firstName"} className={globalStyles.input}
                                   value={this.state.skipperWithPlacesData.skipper.firstName}
                                   onChange={(event) => {
                                       this.setState({skipperWithPlacesData: {...this.state.skipperWithPlacesData, skipper: {...this.state.skipperWithPlacesData.skipper, firstName: event.target.value}}})

                                   }}
                            />
                        </div>
                    </div>

                    <div className={styles.inputRow}>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Adresse e-mail*</label>
                            <input type={"text"} name={"email"} className={globalStyles.input}
                                   value={this.state.skipperWithPlacesData.skipper.email}
                                   onChange={(event) => {
                                       this.setState({skipperWithPlacesData: {...this.state.skipperWithPlacesData, skipper: {...this.state.skipperWithPlacesData.skipper, email: event.target.value}}})
                                   }}
                                   disabled
                            />
                        </div>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Numéro de téléphone*</label>
                            <input type={"text"} name={"firstName"} className={globalStyles.input}
                                   value={this.state.skipperWithPlacesData.skipper.phone}
                                   onChange={(event) => {
                                       this.setState({skipperWithPlacesData: {...this.state.skipperWithPlacesData, skipper: {...this.state.skipperWithPlacesData.skipper, phone: event.target.value}}})
                                   }}
                                   disabled
                            />
                        </div>
                    </div>

                    <div className={styles.inputRow}>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Numéro de licence FFV</label>
                            <input type={"text"} name={"ffv"} className={globalStyles.input}
                                   value={this.state.skipperWithPlacesData.skipper.ffv}
                                   onChange={(event) => {
                                       this.setState({skipperWithPlacesData: {...this.state.skipperWithPlacesData, skipper: {...this.state.skipperWithPlacesData.skipper, ffv: event.target.value}}})

                                   }}
                            />
                        </div>

                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Niveau</label>
                            <div className={globalStyles.inputArea}>
                                <select
                                    className={globalStyles.inputInArea}
                                    value={this.state.skipperWithPlacesData.skipper.sailingLevel}
                                    onChange={(event) => {
                                        this.setState({skipperWithPlacesData: {...this.state.skipperWithPlacesData, skipper: {...this.state.skipperWithPlacesData.skipper, sailingLevel: event.target.value}}})

                                    }}
                                >
                                    <option value={0}>Je n'ai jamais fait de voile</option>
                                    <option value={1}>J'ai des bases en voile</option>
                                    <option value={2}>J'ai des compétences en voile</option>
                                    <option value={3}>J'ai déjà regaté</option>
                                    <option value={4}>Je suis regatier confirmé</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className={styles.inputRow}>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Sexe</label>
                            <div className={globalStyles.inputArea}>
                                <select
                                    className={globalStyles.inputInArea}
                                    value={this.state.skipperWithPlacesData.skipper.sexe}
                                    onChange={(event) => {
                                        this.setState({skipperWithPlacesData: {...this.state.skipperWithPlacesData, skipper: {...this.state.skipperWithPlacesData.skipper, sexe: event.target.value}}})
                                    }}
                                >
                                    <option value={0}>Homme</option>
                                    <option value={1}>Femme</option>
                                </select>
                            </div>
                        </div>
                        <div className={globalStyles.inputContainer} style={{flex: 1}}>
                            <label className={globalStyles.label}>Taille de t-shirt</label>
                            <div className={globalStyles.inputArea}>
                                <select
                                    className={globalStyles.inputInArea}
                                    value={this.state.skipperWithPlacesData.skipper.shirtSize}
                                    onChange={(event) => {
                                        this.setState({skipperWithPlacesData: {...this.state.skipperWithPlacesData, skipper: {...this.state.skipperWithPlacesData.skipper, shirtSize: event.target.value}}})
                                    }}
                                >
                                    <option value={0}>XS</option>
                                    <option value={1}>S</option>
                                    <option value={2}>M</option>
                                    <option value={3}>L</option>
                                    <option value={4}>XL</option>
                                    <option value={5}>XXL</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className={styles.inputRow} style={{position: "relative"}}>
                        <div className={styles.choicePart} style={{padding: 0}}>
                            <b style={{marginBottom: "10px"}}>Votre PACK Week-end GEM est offert !</b>
                            <div className={`${globalStyles.radioContainer} ${styles.clickable}`}
                                 style={{alignItems: "flex-start"}}
                            >
                                <input
                                    type={"checkbox"} name={"type"}
                                    checked={true}
                                    style={{marginTop: "4px"}}
                                    disabled={true}
                                />
                                <div style={{display: "flex", gap: "5px", flexDirection: "column"}}>
                                    <div style={{display: "flex", gap: "10px"}}>
                                        <span>PACK Week-end GEM</span>
                                        <span className={styles.price}>
                                            <span style={{textDecoration: "line-through"}}>{this.getPrice(this.props.regateEventInfos.packPrice)}</span> Offert
                                        </span>
                                    </div>
                                    <div style={{fontSize: "0.8rem"}}>{this.props.regateEventInfos.packCompoString}</div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className={styles.inputRow} style={{position: "relative"}}>
                        <div className={styles.choicePart} style={{padding: 0}}>
                            {this.state.packCompo[1] === "0" &&
                                <div className={`${globalStyles.radioContainer} ${styles.clickable}`}
                                     onClick={() => {
                                         if(!this.props.regateEventInfos.canColloque) return;
                                         this.updateParticipateIn(1)
                                     }}
                                >
                                    <input type={"checkbox"} name={"type"}
                                           checked={partIn[1] === "1"}
                                           disabled={!this.props.regateEventInfos.canColloque}
                                    />
                                    <span style={{textDecoration: this.props.regateEventInfos.canColloque ? 'auto' : 'line-through'}}>Journée du 21 juin : matinée bien-être et colloque "Voile et Santé" (réservée aux soignants et seront prioritaires les soignants inscrits pour le week-end complet)</span>
                                    { this.props.regateEventInfos.canColloque ?
                                        <span className={styles.price}>{this.getPrice(this.props.regateEventInfos.colloquePrice)}</span>
                                        :
                                        <span className={styles.complet}>Complet</span>
                                    }
                                </div>
                            }
                            {this.state.packCompo[2] === "0" &&
                                <div className={`${globalStyles.radioContainer} ${styles.clickable}`}
                                     onClick={() => {
                                         if(!this.props.regateEventInfos.canDinner) return;
                                         this.updateParticipateIn(2);
                                     }}
                                >
                                    <input type={"checkbox"} name={"type"}
                                           checked={partIn[2] === "1"}
                                           disabled={!this.props.regateEventInfos.canDinner}
                                    />
                                    <span style={{textDecoration: this.props.regateEventInfos.canDinner ? 'auto' : 'line-through'}}>Soirée "GEM en Musique" du vendredi 21 juin à l'UNM</span>
                                    { this.props.regateEventInfos.canDinner ?
                                        <span className={styles.price}>{this.getPrice(this.props.regateEventInfos.dinnerPrice)}</span>
                                        :
                                        <span className={styles.complet}>Complet</span>
                                    }
                                </div>
                            }
                            {this.state.packCompo[0] === "0" &&
                                <div className={`${globalStyles.radioContainer} ${styles.clickable}`}
                                     onClick={() => {
                                         if(!this.props.regateEventInfos.canRegate) return;
                                         this.updateParticipateIn(0)
                                     }}
                                >
                                    <input type={"checkbox"} name={"type"}
                                           checked={partIn[0] === "1"}
                                           disabled={!this.props.regateEventInfos.canRegate}
                                    />
                                    <span style={{textDecoration: this.props.regateEventInfos.canRegate ? 'auto' : 'line-through'}}>2 jours sur l'eau : régate du 22 juin et régate du 23 juin (ne comprend pas la soirée du 21 juin)</span>
                                    { this.props.regateEventInfos.canRegate ?
                                        <span className={styles.price}>{this.getPrice(this.props.regateEventInfos.regatePrice)}</span>
                                        :
                                        <span className={styles.complet}>Complet</span>
                                    }
                                </div>
                            }
                            {this.state.packCompo[3] === "0" &&
                                <div className={`${globalStyles.radioContainer} ${styles.clickable}`}
                                     onClick={() => {
                                         if(!this.props.regateEventInfos.canParty) return;
                                         this.updateParticipateIn(3)
                                     }}
                                >
                                    <input type={"checkbox"} name={"type"}
                                           checked={partIn[3] === "1"}
                                           disabled={!this.props.regateEventInfos.canParty}
                                    />
                                    <span style={{textDecoration: this.props.regateEventInfos.canParty ? 'auto' : 'line-through'}}>Soirée festive du samedi 1er juillet à La Nautique</span>
                                    { this.props.regateEventInfos.canParty ?
                                        <span className={styles.price}>{this.getPrice(this.props.regateEventInfos.partyPrice)}</span>
                                        :
                                        <span className={styles.complet}>Complet</span>
                                    }
                                </div>
                            }
                        </div>
                    </div>


                </div>

            </div>
        );
    }
}

export default AvailablePlaces;
