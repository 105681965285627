import React, {Component} from "react"
import globalStyles from "../../styles/globalStyles.module.css"
import styles from "./Intro.module.css"
import clock from "../../assets/icones/clock.png";
import heart from "../../assets/icones/heart.png";
import calendar from "../../assets/icones/calendar.png";

class Intro extends Component {


    render () {

        return (
            <div>
                <h1>Test De Maslach</h1>

                <div className={styles.infosCardContainer}>
                    <div className={styles.infosCard}>
                        <img src={clock} alt={"Minuteur"}/>
                        Il dure moins de 5 minutes.
                    </div>

                    <div className={styles.infosCard}>
                        <img src={heart} alt={"Coeur"}/>
                        Répondez avec honnêteté.
                    </div>

                    <div className={styles.infosCard}>
                        <img src={calendar} alt={"Calendrier"}/>
                        Indiquer selon quelle fréquence vous l’avez ressenti au cours des derniers mois.
                    </div>
                </div>

                <div style={{fontWeight: "bold", margin: "40px 0 20px", fontSize: "20px"}}>
                    Comment percevez-vous votre travail&nbsp;?
                    Etes-vous épuisé(e)&nbsp;?
                    Quelle est votre capacité à gérer votre relation aux autres&nbsp;?
                    Où en êtes-vous sur votre degré d’accomplissement personnel&nbsp;?
                </div>

                <div style={{ margin: "20px 0"}}>
                    Guérir en mer vous propose de prendre le temps de faire le point sur ces questions à travers
                    ce questionnaire ANONYME. Les réponses permettront de mesurer l'ampleur de l'épuisement
                    professionnel chez les professionnels de santé participant à la régate.
                </div>

                <div style={{ margin: "20px 0"}}>
                    Précisez la fréquence à laquelle vous ressentez la description des propositions suivantes :
                    <ul>
                        <li>Jamais</li>
                        <li>Quelques fois par an, au moins</li>
                        <li>Une fois par mois au moins</li>
                        <li>Quelques fois par mois</li>
                        <li>Une fois par semaine</li>
                        <li>Quelques fois par semaine</li>
                        <li>Chaque jour</li>
                    </ul>
                </div>

                <div style={{display: "flex", justifyContent: "center", marginTop: "50px"}}>
                    <button
                        className={globalStyles.button}
                        style={{padding: "10px 60px"}}
                        onClick={() => this.props.goNext()}
                    >
                        Commencer
                    </button>
                </div>
            </div>
        );
    }
}

export default Intro;
