import React from "react"
import globalStyles from "../../styles/globalStyles.module.css"
import styles from "./Regate.module.css"
import TemplateComponent, {api_url, mapStateToProps} from "../../services/APITemplate";
import {connect} from "react-redux";
import Participant from "./Participant";
import AvailablePlaces from "./AvailablePlaces";
import moment from "moment";
import AvailablePlaces2 from "./AvailablePlaces2";
import NoPlaces from "./NoPlaces";
import NoPlaces2 from "./NoPlaces2";
import ConfirmAndPay from "./ConfirmAndPay";

class Regate extends TemplateComponent {

    constructor(props) {
        super(props);
        this.state = {
            part: 0,
            participant: false,
            skipper: false,
            availablePlaces: null,
            participantData: {
                firstName: this.props.user.user.firstName,
                lastName: this.props.user.user.lastName,
                email: this.props.user.user.email,
                phone: this.props.user.user.phone,
                ffv: "",
                sexe: 0,
                shirtSize: 2,
                participateIn: 0,
                packWe: false,
                sailingLevel: 0,
                navigateWith: "",
                navigateOn: ""
            },
            skipperData: {
                boat: {
                    name: "",
                    model: "",
                    length: "",
                    nbPlaces: "",
                    insurance: null,
                    jauge: null
                },
                nbOpenPlaces: "",
                skipper: {
                    lastName: this.props.user.user.lastName,
                    firstName: this.props.user.user.firstName,
                    ffv: "",
                    phone: this.props.user.user.phone,
                    email: this.props.user.user.email,
                    shirtSize: 2,
                    sexe: 0,
                    participateIn: 0,
                    sailingLevel: 0
                },
                crew: [
                    {
                        lastName: "",
                        firstName: "",
                        ffv: "",
                        shirtSize: 2,
                        sexe: 0,
                        participateIn: 0,
                        packWe: false,
                        sailingLevel: 0
                    }
                ]
            },
            regateEvents: [],
            regateEvent: null,
            regateEventInfos : [],
            canNext: false,
        }
        this.load = {
            events: {
                start: false,
                end: false,
            }
        }
    }

    loadData() {
        this.getRegateEvents();
    }

    getRegateEvents(){
        if(!this.load.events.start) {
            this.load.events.start = true
            let request = new Request(api_url + "/regate/events", {method: "GET", credentials: "include"});
            this.fetchResponseOrLoginOrError(request, true).then((response) => {
                return response.json()
            }).then((data) => {
                this.load.events.end = true;
                if(parseInt(window.localStorage.getItem("hasRegateOrder"))) {
                    this.setState(JSON.parse(window.localStorage.getItem("regateOrderState")))
                }
                this.setState({regateEvents: data})
            })
        }
    }

    getRegateEventsRadio() {
        let regateEvents = [];
        if(this.state.regateEvents.length > 0){
            regateEvents.push(
                <span className={styles.question}>Vous souhaitez vous inscrire à :</span>
            )
        } else {
            regateEvents.push(
                <span className={styles.question}>Aucune régate n'est ouverte pour le moment.</span>
            )
        }

        for(let i = 0; i < this.state.regateEvents.length; i++){
            let regateEvent = this.state.regateEvents[i];
            regateEvents.push(
                <div className={`${globalStyles.radioContainer} ${styles.clickable}`} onClick={() => {
                    this.setState({regateEvent: i})
                    this.canNext(true)
                }}>
                    <input
                        type={"radio"}
                        name={"type"}
                        checked={this.state.regateEvent === i}
                        onChange={() => {
                            this.setState({regateEvent: i})
                            this.canNext(true)
                        }}
                    />

                    <div className={styles.regateName}>
                        <span className={styles.answer}>Événement Annuel GEM (4è édition) - {regateEvent.antenne}</span>
                        <span style={{fontWeight: "normal"}}>Du {moment(regateEvent.startDate.date).format("DD/MM/YYYY")} au {moment(regateEvent.endDate.date).format("DD/MM/YYYY")}</span>
                    </div>

                </div>
            )
        }
        return regateEvents;
    }

    doesMustLog() {
        this.mustLog = 1
    }

    canPrev() {
        return this.state.part !== 0;
    }

    prev() {
        this.setState({part: this.state.part - 1})
    }

    canNext(can) {
        this.setState({canNext: can})
    }

    next() {
        if(this.state.participant && this.state.part === 2){
            this.setState({participantData: this.participantInfo.getInfos()})
            // this.sendParticipantInfos()
            setTimeout(() => {
                window.localStorage.setItem("hasRegateOrder", '1');
                window.localStorage.setItem("regateOrderState", JSON.stringify(this.state));
                this.setState({shouldNavigate: true, navigateTo: "/regate/register/" + this.state.regateEvents[this.state.regateEvent].id})
            }, 500);
            return
        }

        if(this.state.skipper && this.state.part === 2 && this.state.availablePlaces){
            this.setState({skipperData: {...this.state.skipperData, skipper: {...this.state.skipperData.skipper, participateIn: this.state.regateEvents[this.state.regateEvent].packCompo}}})
        }
        if(this.state.skipper && this.state.part === 3 && this.state.availablePlaces){
            this.setState({skipperData: this.skipperWithPlacesInfo.getInfos()})
            this.state.skipperData.crew[0].participateIn = this.state.regateEvents[this.state.regateEvent].packCompo;
            this.state.skipperData.crew[0].packWe = true;
            this.forceUpdate();
        }
        if(this.state.skipper && this.state.part === 4 && this.state.availablePlaces){
            this.setState({skipperData: this.skipperWithPlacesInfo2.getInfos()})
            // this.sendSkipperInfos();
            setTimeout(() => {
                window.localStorage.setItem("hasRegateOrder", '1');
                window.localStorage.setItem("regateOrderState", JSON.stringify(this.state));
                this.setState({shouldNavigate: true, navigateTo: "/regate/register/" + this.state.regateEvents[this.state.regateEvent].id})
            }, 500);
            return
        }

        if(this.state.skipper && this.state.part === 3 && !this.state.availablePlaces){
            this.setState({skipperData: this.skipperInfo.getInfos()})
            this.forceUpdate();
        }
        if(this.state.skipper && this.state.part === 4 && !this.state.availablePlaces){
            this.setState({skipperData: this.skipperInfo2.getInfos()})
            // this.sendSkipperInfos();
            setTimeout(() => {
                window.localStorage.setItem("hasRegateOrder", '1');
                window.localStorage.setItem("regateOrderState", JSON.stringify(this.state));
                this.setState({shouldNavigate: true, navigateTo: "/regate/register/" + this.state.regateEvents[this.state.regateEvent].id})
            }, 500);
            return
        }

        this.setState({part: this.state.part + 1, canNext: false})
    }

    sendParticipantInfos() {
        let infos = new FormData();
        let data = this.participantInfo.getInfos()
        infos.append("json", JSON.stringify(data))

        let request = new Request(api_url + '/regate/register/participant/' + this.state.regateEvents[this.state.regateEvent].id, {method:'POST', body: infos, credentials: 'include'});
        this.fetchResponseOrLoginOrError(request, true).then(response => {
            return response.json()
        }).then((data) => {
            if(data.success) {

            } else {
                this.setState({error: data.error})
            }
        })
    }

    sendSkipperInfos() {
        let infos = new FormData();
        let data = null;
        if(this.state.availablePlaces){
            data = this.skipperWithPlacesInfo2.getInfos()
        } else {
            data = this.skipperInfo2.getInfos()
        }
        infos.append("json", JSON.stringify(data))
        infos.append("insurance", data.boat.insurance)
        infos.append("jauge", data.boat.jauge)

        let request = new Request(api_url + '/regate/register/skipper/' + this.state.regateEvents[this.state.regateEvent].id, {method:'POST', body: infos, credentials: 'include'});
        this.fetchResponseOrLoginOrError(request, true).then(response => {
            return response.json()
        }).then((data) => {
            if(data.success) {

            } else {
                this.setState({error: data.error})
            }
        })
    }



    render () {
        if(!this.canRender()) {
            return this.noRender()
        }
        return (
            <div>
                {this.head()}
                {this.flashtoDisplay}

                <div className={`${globalStyles.mainContainer} ${styles.mainContainer}`}>

                    {this.state.part === 0 &&
                        <div className={styles.content}>
                            {this.state.regateEvents && this.getRegateEventsRadio()}
                        </div>
                    }

                    {this.state.part === 1 &&
                        <div className={styles.content}>
                            <span className={styles.question}>Vous êtes :</span>
                            <div className={`${globalStyles.radioContainer} ${styles.clickable}`}
                                 onClick={() => {
                                     this.setState({participant: true, skipper: false})
                                     this.canNext(true)
                                 }}>
                                <input
                                    type={"radio"}
                                    name={"type"}
                                    checked={this.state.participant}
                                    onChange={() => {
                                        this.setState({participant: true, skipper: false})
                                        this.canNext(true)
                                    }}
                                />
                                <span className={styles.answer}>Un participant</span>
                            </div>
                            <div className={`${globalStyles.radioContainer} ${styles.clickable}`} onClick={() => {
                                this.setState({participant: false, skipper: true})
                                this.canNext(true)
                            }}>
                                <input
                                    type={"radio"}
                                    name={"type"}
                                    checked={this.state.skipper}
                                    onChange={() => {
                                        this.setState({participant: false, skipper: true})
                                        this.canNext(true)
                                    }}
                                />
                                <span className={styles.answer}>Un skipper/propriétaire</span>
                            </div>
                        </div>
                    }

                    {this.state.part === 2 && this.state.participant &&
                        <Participant
                            ref={(node) => this.participantInfo = node}
                            participantData={this.state.participantData}
                            regateEventInfos={this.state.regateEvents[this.state.regateEvent]}
                            canNext={this.canNext.bind(this)}
                            currentCanNext={this.state.canNext}
                        />
                    }

                    {this.state.part === 2 && this.state.skipper &&
                        <div className={styles.content}>
                            <div className={`${globalStyles.radioContainer} ${styles.clickable}`} onClick={() => {
                                this.setState({availablePlaces: true})
                                this.canNext(true)
                            }}>
                                <input
                                    type={"radio"}
                                    name={"type"}
                                    style={{marginBottom: 16}}
                                    checked={this.state.availablePlaces}
                                    onChange={() => {
                                        this.setState({availablePlaces: true})
                                        this.canNext(true)
                                    }}
                                />
                                <div style={{flexDirection: "column", display: "flex"}}>
                                    <span className={styles.answer}>
                                        Je souhaite accueillir gracieusement des participants à la régate.
                                    </span>
                                    <span style={{fontWeight: "normal"}}>
                                        Frais d’inscription offerts (voir modalités page suivante)
                                    </span>
                                </div>
                            </div>
                            <div className={`${globalStyles.radioContainer} ${styles.clickable}`} onClick={() => {
                                this.setState({availablePlaces: false})
                                this.canNext(true)
                            }}><input
                                    type={"radio"}
                                    name={"type"}
                                    checked={this.state.availablePlaces === false}
                                    onChange={() => {
                                        this.setState({availablePlaces: false})
                                        this.canNext(true)
                                    }}
                                />
                                <span className={styles.answer}>
                                    J'ai déjà mon équipage et/ou je souhaite louer mon bateau.
                                </span>
                            </div>
                        </div>
                    }

                    {this.state.part === 3 && this.state.skipper && this.state.availablePlaces &&
                        <AvailablePlaces
                            skipperWithPlacesData={this.state.skipperData}
                            regateEventInfos={this.state.regateEvents[this.state.regateEvent]}
                            ref={(node) => this.skipperWithPlacesInfo = node}
                            canNext={this.canNext.bind(this)}
                            currentCanNext={this.state.canNext}
                        />
                    }

                    {this.state.part === 4 && this.state.skipper && this.state.availablePlaces &&
                        <AvailablePlaces2
                            skipperWithPlacesData={this.state.skipperData}
                            regateEventInfos={this.state.regateEvents[this.state.regateEvent]}
                            ref={(node) => this.skipperWithPlacesInfo2 = node}
                            canNext={this.canNext.bind(this)}
                            currentCanNext={this.state.canNext}
                        />
                    }

                    {this.state.part === 3 && this.state.skipper && !this.state.availablePlaces &&
                        <NoPlaces
                            skipperData={this.state.skipperData}
                            regateEventInfos={this.state.regateEvents[this.state.regateEvent]}
                            ref={(node) => this.skipperInfo = node}
                            canNext={this.canNext.bind(this)}
                            currentCanNext={this.state.canNext}
                        />
                    }

                    {this.state.part === 4 && this.state.skipper && !this.state.availablePlaces &&
                        <NoPlaces2
                            skipperData={this.state.skipperData}
                            regateEventInfos={this.state.regateEvents[this.state.regateEvent]}
                            ref={(node) => this.skipperInfo2 = node}
                            canNext={this.canNext.bind(this)}
                            currentCanNext={this.state.canNext}
                        />
                    }

                    <div style={{position: "absolute", bottom: 100, display: "flex", justifyContent: "space-between", maxWidth: "1200px", width: "100%", gap: "10px"}}>
                        <button
                            className={globalStyles.button}
                            style={{width: "200px", visibility: this.canPrev() ? "visible": "hidden"}}
                            onClick={this.prev.bind(this)}
                        >
                            Précédent
                        </button>
                        <button
                            className={globalStyles.button}
                            style={{width: "200px", visibility: this.state.canNext ? "visible": "hidden"}}
                            onClick={this.next.bind(this)}
                        >
                            Suivant
                        </button>
                    </div>
                </div>

                {this.foot()}
            </div>
        );
    }
}

export default connect(mapStateToProps)(Regate);
