import React from "react"
import globalStyles from "../../styles/globalStyles.module.css"
import Question from "./Question";
import Intro from "./Intro";
import Resultat from "./Resultat";
import {connect} from "react-redux";
import TemplateComponent, {api_url, mapStateToProps} from "../../services/APITemplate";
import testMaslach from "../../ressources/testMaslach.json"

class Maslach extends TemplateComponent {

    constructor(props) {
        super(props);
        this.state = {
            part: 0,
            answers: {}
        }
    }

    addAnswer(number, value){
        this.state.answers[number] = value;
        this.forceUpdate();
    }

    calculateScore () {
        let EP = 0;
        let D = 0;
        let AP = 0;
        for(let i = 0; i < testMaslach.length; i++){
            if((i + 1) in this.state.answers){
                if(testMaslach[i].type === "EP"){
                    EP += this.state.answers[i + 1];
                } else if (testMaslach[i].type === "AP"){
                    AP += this.state.answers[i + 1];
                } else if (testMaslach[i].type === "D"){
                    D += this.state.answers[i + 1];
                }
            }
        }

        return {"EP": EP, "D": D, "AP": AP}
    }

    getAnswers() {
        let answers = [];
        for(let i = 0; i < testMaslach.length; i++){
            answers.push({
                question: i + 1,
                type: testMaslach[i].type,
                answer: this.state.answers[i + 1]
            })
        }
        return answers
    }

    finish() {
        let infos = new FormData();
        let data = {
            answers: this.getAnswers(),
            score: this.calculateScore()
        }
        infos.append("json", JSON.stringify(data))

        let request = new Request(api_url + '/test/answers', {method:'POST', body: infos, credentials: "include"});
        this.fetchResponseOrLoginOrError(request, true).then(response => {
            return response.json()
        }).then((data) => {
            if(data.save) {

            }
        })
    }

    goPrevious() {
        this.setState({part: this.state.part - 1})
    }


    goNext() {
        if(this.state.part === testMaslach.length){
            this.finish()
        }
        this.setState({part: this.state.part + 1})
    }

    doesMustLog() {
        this.mustLog = 1
    }


    loadData() {}

    render() {
        if(!this.canRender()) {
            return this.noRender()
        }
        return (
            <div style={{minHeight:  "calc(100vh - 80px)", position: "relative"}}>
                {this.head()}
                {this.flashtoDisplay}
                <div className={globalStyles.mainContainer}>

                    {this.state.part === 0 &&
                        <Intro
                            goNext={this.goNext.bind(this)}
                        />
                    }

                    {this.state.part >= 1 && this.state.part <= testMaslach.length &&
                        <Question
                            addAnswer={this.addAnswer.bind(this)}
                            goPrevious={this.goPrevious.bind(this)}
                            goNext={this.goNext.bind(this)}
                            question={testMaslach[this.state.part - 1].question}
                            number={this.state.part}
                            answer={this.state.part in this.state.answers ? this.state.answers[this.state.part] : null}
                        />
                    }

                    {this.state.part === (1 + testMaslach.length) &&
                        <Resultat
                            EP={this.calculateScore()["EP"]}
                            D={this.calculateScore()["D"]}
                            AP={this.calculateScore()["AP"]}
                            event={this.props.event}
                        />
                    }
                </div>
                {this.foot()}
            </div>
        )
    }

}

export default connect(mapStateToProps)(Maslach) ;
